//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useContext, useRef, CSSProperties } from "react"
import { Control } from "./Control"
import { Icon, SmartCheckBox, SmartDiv, SmartInput, SmartRow, useEffect2, VecIcon } from "./misc"
import { ColumnData, DataTypes, DateRanges, FieldCountData, FieldTypes, GridProps, MachshevetClient, PickListItem, SettingGroup } from "./Declarations"
import { dictionaryValues, enumList, KVPair, numberColor, parseDate, recordValues } from "./globals"
import { ColumnData2, ControlProps2, MainContext } from "./styles"

interface colProps {
    column: ColumnData
    stateSetter: (state: ColumnData) => void
    gridProps: GridProps
    fullPage: boolean
    editModeToggler: () => void
    showTools: boolean
    commands?: ColumnData[]
    refresher: () => void
}

export const ColumnHeader: FC<colProps> = props => {
    const ctx = useContext(MainContext)
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [searchOptions, setSearchOptions] = useState<FieldCountData[]>([])
    const [showButton, setShowButton] = useState<boolean>()
    const [filterText, setFilterText] = useState(props.column.FilterText)
    const cd = props.column

    const topstyle: CSSProperties = { verticalAlign: "top" }
    if (cd.Visible === false) topstyle.color = "lightgray"
    const cp = new ControlProps2()
    cp.memberData = cd
    cp.reloader = props.refresher
    cp.recordType = props.gridProps.RecordType

    const isfornfilt = cd.ForeignTypeName && cd.DataType !== DataTypes.Boolean
    function handleCheck(Key: string) {
        let selected = isfornfilt ? (cd.IDFilter || "").split(",") : JSON.parse(cd.FilterList || "[]") as string[]
        if (selected.includes(Key)) {
            const i = selected.indexOf(Key)
            selected.splice(i, 1)
        }
        else {
            selected = selected.concat(Key)
        }
        if (isfornfilt) cd.IDFilter = selected.join()
        else cd.FilterList = selected.length ? JSON.stringify(selected) : undefined
        props.stateSetter(cd)
    }
    function isSelected(value: string): boolean {
        let checked = false
        if (value != undefined) {
            let selected: string[] = JSON.parse(cd.FilterList || "[]")
            if (isfornfilt) selected = (cd.IDFilter || "").split(",")
            checked = selected.some(x => x === value)
        }
        return checked
    }

    const abortController = useRef(new AbortController())
    async function checkOptions(forceRefresh?: boolean) {
        const torefresh = !searchOptions.length || forceRefresh
        if (torefresh) {
            let term = cd.FilterText!
            if (cd.DataType === DataTypes.DateTime && term) {
                const dt = parseDate(term, ctx.data.DateEditFormat)
                if (dt) term = dt.toISOString()
            }
            abortController.current.abort()
            abortController.current = new AbortController()
            const data = await MachshevetClient.FieldCount(props.gridProps, cd.Name!, term, abortController.current.signal)
            setSearchOptions(data)
        }
    }

    useEffect2(async () => {
        if (showFilter) await checkOptions(true)
    }, [showFilter])

    function Filter(Name: string, FieldName: 'MinFilter' | 'MaxFilter' | 'FilterText' | 'IncludeEmpties' | 'DateRange' | 'AutoFilter' | 'ReverseFilter' | 'StartSpan' | 'EndSpan', DataType?: DataTypes, FieldType?: FieldTypes, Picklist?: PickListItem[]) {
        const cp2 = new ControlProps2()//  defaultControlProps()
        cp2.memberData = { ...cd }
        if (DataType == DataTypes.Text) cp2.memberData.DataType = DataTypes.Text
        cp2.memberData.DataType = DataType || cd.DataType
        cp2.memberData.Name = FieldName
        cp2.Value = cd[FieldName]
        cp2.Text = cp2.Value
        if (FieldType) cp2.memberData.FieldType = FieldType
        if (cp2.memberData.FieldType === FieldTypes.Html) cp2.memberData.FieldType = undefined

        if (Picklist) {
            cp2.memberData.PickList = Picklist
            cp2.memberData.HasPickList = true
            cp2.memberData.DataType = undefined
            cp2.memberData.FieldType = undefined
        } else {
            cp2.memberData.HasPickList = false
        }
        cp2.recordType = undefined//so it should not call server for refreshing options
        cp2.memberData.ForeignTypeName = undefined
        cp2.memberData.Locked = false
        cp2.memberData.Editable = true
        cp2.memberData.EditMode = true
        cp2.memberData.AutoComplete = false
        cp2.memberData.MergePicker = false
        cp2.style = { padding: 1, flexGrow: 1 }
        if (cp2.memberData.DataType == DataTypes.Boolean) cp2.memberData.Required = true

        return <div style={{ display: "flex", gap: 3 }}> {ctx.localized(Name)}
            <Control field={{
                ...cp2, onChange: async e => {
                    const fltval = e
                    if (FieldName == 'IncludeEmpties') cd.IncludeEmpties = fltval
                    else if (FieldName == 'ReverseFilter') cd.ReverseFilter = fltval
                    else cd[FieldName] = fltval
                    props.stateSetter(cd)
                    await checkOptions(true)
                }
            }} /></div>
    }

    const stlname: CSSProperties = { color: "#296067", cursor: "default", display: "flex", justifyContent: "center", gap: 5 }
    if (cd.AutoFilter !== undefined || cd.DateRange || cd.FilterList || cd.FilterText || cd.IDFilter || cd.MinFilter || cd.MaxFilter) stlname.color = "orange";

    // const autfilters = recordValues(cd.AutoFilterList || []).map(x => ({ Key: Number(x.Key), Value: '' + x.Value }))
    const autfilters = dictionaryValues(cd.AutoFilterList || [])//.map(x => ({ Key: Number(x.Key), Value: '' + x.Value }))

    return <th id={"TestColumn" + cd.Name} style={topstyle} onMouseLeave={() => setShowButton(false)} onMouseEnter={() => setShowButton(true)} draggable onDragStart={e => e.dataTransfer.setData('text/plain', cd.Name!)} onDragOver={e => e.preventDefault()}
        onDrop={async e => {
            const drg = e.dataTransfer.getData('text/plain')
            if (drg) {
                await MachshevetClient.MoveMember(props.gridProps.RecordType!, SettingGroup.Columns, drg, cd.Name!, props.gridProps.SettingKey, props.gridProps.ReportID)
                props.refresher()
            }
        }} >
        <SmartDiv id={"TestSort_" + cd.Name} style={stlname} onContextMenu={e => ctx.showContextMenu!(e, undefined, props.commands?.map(x => x as ColumnData2), cp, undefined, props.stateSetter, props.refresher, props.gridProps)} onClick={e => {
            if (e.ctrlKey) {
                if (cd.SortOrder !== undefined) cd.SortDescending = !cd.SortDescending
                else {
                    const last = Math.max(...props.gridProps.Filters.map(y => y.SortOrder || -1))
                    cd.SortOrder = last + 1
                }
            } else {
                if (cd.SortOrder) cd.SortDescending = !cd.SortDescending
                cd.SortOrder = 1
                props.gridProps.Filters?.forEach(x => {
                    if (x.ColumnKey !== cd.ColumnKey) x.SortOrder = undefined
                })
            }
            props.stateSetter(cd)
        }}>   <span id={"Test_Title_" + cd.Name} title={cd.Tooltip} style={{ fontWeight: "bold", whiteSpace: ctx.data.GridHeaderWrapping ? "break-spaces" : "pre", fontSize: "90%" }}>{cd.LocalName}</span>
            {cd.SortOrder && <span id={"TestSort_Order_" + cd.Name} >{cd.SortDescending ? "▲" : "▼"}</span>}
        </SmartDiv>

        {ctx.data.DisplayQuickFilter && props.showTools && !cd.IsCommand && <div style={{ display: "flex" }}>
            <SmartInput type="text" id={"TestQuickFilter" + cd.Name} style={{ fontSize: "80%", padding: 1, flexGrow: 1 }} value={filterText}
                onChange={e => {
                    const term = e.currentTarget.value
                    setFilterText(term)
                    cd.FilterText = term
                    props.stateSetter(cd)
                }} />
            <div id="TestFilterButton">
                <Icon name="Filter" visibility={showButton ? "visible" : "hidden"} onClick={async () => {
                    setShowFilter(!showFilter)
                    await checkOptions()
                }} />
            </div>
        </div>}

        {showFilter && <div id={"TestFilterBox" + cd.Name}  style={{ position: "absolute", display: "flex", flexDirection: "column", backgroundColor: "white", gap: 3, padding: ".5ch", borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: numberColor(ctx.data.SecondaryColor), maxHeight: "50vh", fontSize: "90%" }} >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Icon name="EditMode" title={ctx.localized("EditMode")} onClick={() => {
                    props.editModeToggler()
                    setShowFilter(false)
                }} />
                <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }} id="TestCloseFilter" onClick={() => setShowFilter(false)}>✖</div>
            </div>
            {autfilters.length > 0 && Filter("Auto", "AutoFilter", DataTypes.Enum, undefined, autfilters)}
            {cd.DataType === DataTypes.DateTime && <> {Filter("Range", "DateRange", DataTypes.Enum, undefined, enumList(DateRanges).map(x => KVPair(x.Key, ctx.localized(x.Value))))} {Filter("From", "MinFilter")} {Filter("Till", "MaxFilter")}  {Filter("StartSpan", "StartSpan", DataTypes.Number, FieldTypes.Span)}{Filter("EndSpan", "EndSpan", DataTypes.Number, FieldTypes.Span)}</>}
            {cd.DataType === DataTypes.Number && <>{Filter("From", "MinFilter")} {Filter("Till", "MaxFilter")} </>}
            {Filter("IncludeEmpties", "IncludeEmpties", DataTypes.Boolean)}
            {Filter("Reverse", "ReverseFilter", DataTypes.Boolean)}
            <div style={{ overflowY: "auto", display: "flex", flexDirection: "column" }}>
                <SmartInput id="TestFilterText" placeholder={ctx.localized("Search")} style={{ padding: 1 }} onChange={async e => {
                    const term = e.currentTarget.value
                    cd.FilterText = term
                    props.stateSetter(cd)
                    await checkOptions(true)
                }} />
                <table id="TestSearchOptions" style={{ borderSpacing: 0, overflowY: "auto" }}>
                    <tbody>
                        {searchOptions.map(x => {
                            const issel = isSelected(x.Key!)
                            return <SmartRow id="TestSearchOptionRow" key={x.Key} style={{ fontWeight: issel ? "bold" : "unset" }} onClick={() => handleCheck(x.Key!)}>
                                <td style={{ padding: 0 }}>
                                    <SmartCheckBox id={"TestOption_" + x.Key} checked={issel} />
                                </td>
                                <td style={{ textAlign: "start", padding: 0 }}>
                                    {x.Value}
                                </td>
                                <td style={{ color: "gray", padding: 0, textAlign: "end" }} id="Test_Count">
                                    {x.Count}
                                </td>
                            </SmartRow>
                        }
                        )}
                    </tbody>
                </table>
            </div>

        </div>}
    </th>
}