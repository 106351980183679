//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, FC, useState, useEffect } from "react"
import { Icon, MyTab, MyTabs, SmartCount, SmartDiv, SmartText, VecIcon } from './misc'
import { DataTypes, GridProps, MachshevetClient, RecordsData, ReportLite } from './Declarations'
import { adjustColor, getControlProps2, getClientUrl, headerItem, numberColor, redirect, controlRecord2, getControlProps3 } from './globals'
import { ListView } from "./ListView"
import { MainContext } from "./styles"
import { RecordForm } from "./RecordForm"
import { useParams } from "react-router-dom"

export const RecordPage: FC<{ fullPage?: boolean, activeTab?: number }> = props => {
    const ctx = useContext(MainContext)
    const [state, setState] = useState<RecordsData>()
    const [headers, setHeaders] = useState<headerItem[]>([])
    const [listProps, setListProps] = useState<GridProps[]>([])
    const [activeTab, setActiveTab] = useState(0)
    const { RecordType, ID } = useParams()
    const recrd = state?.Records[0]
    const recid = ID ? +ID : undefined
    useEffect(() => {
        if (state?.Reports) {



            setListProps(prev => {
                // Create a map of existing ReportIDs for quick lookup
                //const existingIds = new Set(prev.map(item => item.ReportID));

                // Add new reports from state.Reports
                const updatedList = state.Reports.map(x => {
                    const existingItem = prev.find(y => y.ReportID === x.ID);
                    if (existingItem) {
                        // Keep the existing item
                        return existingItem;
                    } else {
                        // Create a new GridProps for new reports
                        const gp = new GridProps();
                        gp.ReportID = x.ID;
                        //gp.RecordValues = controlRecord2(getControlProps3(recrd!, state.Columns), recid);
                        return gp;
                    }
                });

                // Filter out items that are no longer in state.Reports
                var ret = updatedList.filter(item => state.Reports.some(x => x.ID === item.ReportID));
                var recvals= controlRecord2(getControlProps3(recrd!, state.Columns), recid);
                ret.forEach(x => x.RecordValues = recvals)
                return ret

            });


            //setListProps(state.Reports.map(x => {
            //    const gp = new GridProps()
            //    gp.ReportID = x.ID
            //    gp.RecordValues = controlRecord2(getControlProps3(recrd!, state.Columns), recid)
            //    return gp
            //}))

            //setListProps(prev => {
            //    state.Reports.forEach(x => {
            //        const r = prev.find(y => y.ReportID === x.ID)

            //    })
            //    return prev
            //})


        }
    }, [state?.Reports])


    useEffect(() => {
        setListProps(prev => prev.map(z => ({ ...z, RecordValues: { ID } })))
        setActiveTab(0)
    }, [ID])

    useEffect(() => {
        if (recrd) ctx.setPageTitle!(recrd.RecordName + ' · ' + ctx.localized(RecordType!), RecordType)
    }, [recrd?.RecordID, recrd?.RecordName, ctx.data.Language])

    const dummy = new ReportLite()
    dummy.ID = -1
    //dummy.Name = ctx.localized('General')
    dummy.LocalName = ctx.localized('General')
    let reps = [dummy]
    if (state) reps = reps.concat(state.Reports)

    const flds = recrd?.Fields.length && state?.Columns.length ? getControlProps2(recrd.Fields, state.Columns) : undefined//odd! how come are there ever fields without columns?
    //flds = flds?.filter(x => x.memberData)//for some odd reason after adding a field, it takes a few seconds till it is also in fields and also in columns

    //const listfield = new ControlProps2()
    return <div style={{ padding: "2vh", overflowY: 'auto', height: "100%", display: 'flex', flexDirection: 'column', gap: "2vh" }} id="div10">
        <SmartDiv style={{ display: "flex", gap: 10 }} onDragOver={e => e.preventDefault()} onDrop={async e => {
            const drg = e.dataTransfer.getData('text/plain')
            await MachshevetClient.SetFieldSetting(state?.RecordType!, drg, "ForHeader", true)
            //doRefresh(prev => prev + 1)
        }}>{flds && flds.filter(x => x.memberData.ForHeader).sortBy(x => x.memberData.Position).map(x => {
            const colr = x.memberData.ForeColor || ctx.data.PrimaryColor
            return <div key={x.memberData.Name} style={{ display: "flex", flexGrow: 1, borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: numberColor(colr), gap: 10, padding: "1vh" }}>
                <VecIcon name={x.memberData.Icon!} color={numberColor(colr)} />
                <div style={{ fontSize: "140%", color: numberColor(colr), fontWeight: "bold" }}>
                    {x.memberData.DataType == DataTypes.Number ? <SmartCount Number={x.Value} fieldType={x.memberData.FieldType} /> : <SmartText Text={x.Value} />}
                    <div style={{ fontSize: "50%" }}>{x.memberData.LocalName}</div>
                </div>
            </div>
        })}</SmartDiv>
        {/*selectedTabChanged={setActiveTab} activeTab={activeTab}*/}
        <MyTabs activeTab={activeTab}>
            {reps.map((x, i) => {
                const rep = x
                const hdr = headers.find(y => y.id === rep.ID)
                let gp = listProps.find(y => y.ReportID === x.ID)
                return <MyTab key={rep.ID} style={{ backgroundColor: numberColor(rep.BackColor) }} reportID={rep.ID} testName={"ReportTab_" + rep.ID} titleElement={
                    <SmartDiv style={{ display: 'flex', gap: "1vh" }} draggable onDragOver={e => e.preventDefault()} onDragStart={e => e.dataTransfer.setData('text/plain', rep.ID.toString())} onDrop={async e => {
                        const drg = e.dataTransfer.getData('text/plain')
                        if (drg) {
                            await MachshevetClient.MoveReport(+drg, rep.ID)
                            //doRefresh(prev => prev + 1)//not sure this really helps
                            //await reload()
                        }
                    }}>
                        <div style={{ display: "flex" }}>
                            <Icon name={rep.RecordType || 'General'} />&nbsp;
                            <span style={{ fontWeight: 'bold' }}>{rep.LocalName!}</span>
                        </div>
                        {hdr?.aggregation && <div style={{ backgroundColor: adjustColor(ctx.data.SecondaryColor!, .5), borderRadius: ".6vh", fontSize: "80%", padding: ".5vh" }}>
                            <SmartCount Number={hdr.aggregation} fieldType={hdr.column?.FieldType} />
                        </div>}
                        {hdr?.count && <SmartCount Number={hdr.count} />}
                    </SmartDiv>
                }
                    onClick={async () => {
                        //const url = getClientUrl(undefined, RecordType, recid, undefined, i)
                        //window.location.hash = url
                        setActiveTab(i)
                        if (rep.ID > 0) await MachshevetClient.MarkReportSeen(rep.ID)
                        //if (props.redirectActiveTab !== false) {
                        //    //const url = getClientUrl(undefined, rec2?.RecordType, recid, undefined, i)
                        //}
                    }} >
                    <div style={{ padding: "1vh", overflowY: "auto", display: "flex", flexGrow: 1 }}>
                        {rep.ID === -1 ?
                            <RecordForm onRefreshed={e => {
                                //if (e) {
                                //setState(prev => ({ ...prev!, Records: e.Records, Columns: e.Columns, Reports: e.Reports }))
                                setState(e)
                                //setReports(e.Reports)
                                //}
                            }} onSaved={(id, oldid) => {
                                ctx.showToast!(ctx.localized('Saved'))
                                //if (!oldid) redirect(undefined, rec2?.RecordType, id)
                                if (!oldid) redirect(undefined, RecordType, id)
                                if (ctx.data.ReturnOnSave) window.history.back()
                            }} />
                            :
                            <ListView showTools={true} gridProps={gp} autoRecordType={false} onResultsFetched={hdr => {
                                setHeaders(prev => {
                                    const rp = prev.find(y => y.id === rep.ID)
                                    if (!rp) prev.push({ id: rep.ID, column: hdr.column })
                                    return prev.map(y => y.id === rep.ID ? { ...y, count: hdr.count, aggregation: hdr.aggregation } : y)
                                })
                            }} />
                        }
                    </div>
                </MyTab>
            })
            }
        </MyTabs>
    </div>
}