//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useContext } from 'react'
import { Control } from './Control'
import { MachshevetClient, RecordsData } from './Declarations'
import { adjustColor, filteredProps, getControlProps2, groupBy, recordValues } from './globals'
import { DialogPlus, SmartButton, SmartDiv, SmartInput, useEffect2 } from './misc'
import { MainContext } from './styles'

export const Options: FC<{ onCancel: () => void }> = props => {
    const ctx = useContext(MainContext)!
    const [searchTerm, setSearchTerm] = useState<string>()
    const [group, setGroup] = useState<string>()
    const [state, setState] = useState<RecordsData>()
    const [changes, setChanges] = useState<Record<string, any>>({})
    useEffect2(async () => {
        const options = await MachshevetClient.OptionList()
        setState(options)
    }, [])

    let fltrd = state && getControlProps2(state.Records[0].Fields, state.Columns)
    fltrd = fltrd && filteredProps(fltrd, searchTerm)
    const groups = fltrd && groupBy(fltrd, x => x.memberData.Group!)
    if (group) fltrd = fltrd && fltrd.filter(x => x.memberData.Group === group)

    const showopts = searchTerm !== undefined || group !== undefined
    return <DialogPlus {...props} onClose={props.onCancel} title={ctx.localized('Options')} footer={[
        <SmartButton key="Save" testName="Save" onClick={async () => {
            const sav: any = {}
            //Object.entries(changes).map(x => {
            recordValues(changes).map(x => {
                //const val = x[1]
                const val = x.Value
                //let ky = x[0]
                let ky = x.Key
                if (val instanceof File) ky = ky + "_File"
                sav[ky] = val
            }
            )
            await MachshevetClient.SetOptions(sav)
            props.onCancel()

        }} disabled={Object.keys(changes).length < 1}  >{ctx.localized('Save')} </SmartButton>,
    ]}    >
        <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'var(--primary-light)' }}>
                <SmartInput id="TestSearch" type='search' value={searchTerm} onChange={e => setSearchTerm(e.currentTarget.value)} autoFocus />
                <div style={{ overflowY: 'auto' }}>
                    {groups && [...groups.keys()].sortBy(x => x).map(grp => ([
                        <SmartDiv key={grp} style={{ padding: '0.2em 0.7em', fontWeight: 'bold', fontSize: '110%' }} onClick={() => setGroup(grp)}>{grp}</SmartDiv>,
                        searchTerm ?
                            fltrd && fltrd.filter(x => x.memberData.Group === grp).sortBy(x => x.memberData.LocalName).map((item, i) =>
                                <div id={"TestOption_" + item.memberData.Name} key={grp + i} style={{ padding: '0.2em 0.7em', cursor: 'pointer' }} >{item.memberData!.LocalName}</div>) :
                            undefined
                    ]))}
                </div>
            </div>

            {showopts && <div>
                {fltrd && fltrd.map(item => {
                    return <tr key={item.memberData.Name} id={"Test_Option_" + item.memberData.Name} data-fieldtype={item.memberData.FieldType}>
                        {!item.memberData.IsCommand ?
                            <>
                                <th style={{ color: adjustColor(ctx.data.PrimaryColor!, .6) }}>{item.memberData.LocalName}:</th>
                                <td >
                                    <Control field={{
                                        ...item, showTools: true, memberData: { ...item.memberData, EditMode: true }, onChange: async e => {
                                            setState(prev => {
                                                const rec = prev
                                                rec!.Records[0].Fields = prev!.Records[0].Fields.map(x => x.Key == item.Key ? { ...x, Value: e, Text: e } : x)
                                                return rec
                                            })
                                            setChanges({ ...changes, [item.memberData.Name!]: e })
                                        }
                                    }} />
                                </td>
                            </> :
                            <td colSpan={2}><SmartButton onClick={() => MachshevetClient.DoCommand(item.memberData.Name!)}>{item.memberData.LocalName}</SmartButton></td>}
                    </tr>
                })
                }
            </div>}
        </div>
    </DialogPlus>
}
