//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useRef, useState } from "react"
import { ColumnData, GridProps, LiteRecordData, MachshevetClient, RecordsData, SettingGroup } from "./Declarations"
import { controlRecord2, defaultGridProps, getControlProps2, getControlProps3 } from "./globals"
import { Control } from "./Control"
import { HamburgerMenu, Icon, NavLink, SmartButton, useEffect2, VecIcon } from "./misc"
import { AppContext, ColumnData2, ControlProps2, doCommand, MainContext, prioritizeCommands } from "./styles"
import { ListView } from "./ListView"
import { useParams } from "react-router-dom"

export const InsertGrid: FC = () => {
    const [commands, setCommands] = useState<ColumnData[]>([])
    const [hamburgerShown, setHamburgerShown] = useState(false)
    //const [gridProps, setGridProps] = useState<GridProps>()
    const [gridProps, setGridProps] = useState(new GridProps())

    const { RecordType } = useParams()

    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const aborter = useRef(new AbortController())
    const [changeFlag, setChangeFlag] = useState(0)
    const [changeRow, setChangeRow] = useState(0)
    const rd = new RecordsData()
    const rt = RecordType!
    rd.RecordType = rt
    const [state, setState] = useState<RecordsData>()

    //const gp2 = defaultGridProps()
    //gp2.RecordType = rt
    //const cdfilt = new ColumnData()
    //cdfilt.Name = "ID"
    //cdfilt.IDFilter = "0"
    //cdfilt.AutoFilterList = undefined//hack. cannot be deserialized correctly
    //gp2.Filters.push(cdfilt)
    //const [preFilter, setPreFilter] = useState<Record<string, unknown>>()

    //const gp = defaultGridProps()
    //gp.SettingGroup = SettingGroup.EditColumns
    //gp.RecordType = rt

    async function getSample() {
        const stt = await MachshevetClient.InsertGrid(rt)
        stt.Records[0].IsDeleted = true//so shouldnt show in ui
        setState(stt)
        //const cmnds = await MachshevetClient.Commands(rt)
        //    const cmnds = await MachshevetClient.Commands(gridProps)
        //    setCommands(cmnds)
    }
    useEffect2(async () => {
        const cdfilt = new ColumnData()
        cdfilt.Name = "ID"
        cdfilt.IDFilter = "0"
        cdfilt.AutoFilterList = undefined//hack. cannot be deserialized correctly
        //gp2.Filters.push(cdfilt)
        setGridProps(prev => ({ ...prev, RecordType: RecordType, SettingGroup: SettingGroup.EditColumns, Filters: [cdfilt] }))
    }, [RecordType])
    useEffect2(async () => {
        if (gridProps.RecordType) {
            const cmnds = await MachshevetClient.Commands(gridProps)
            setCommands(cmnds)
        }
    }, [gridProps.RecordType])
    useEffect2(async () => {
        await getSample()
    }, [])

    useEffect2(async () => {
        if (state) {
            aborter.current.abort()
            aborter.current = new AbortController()
            const signal = aborter.current.signal
            const rec = state.Records[changeRow]
            const flds = getControlProps2(rec.Fields, state!.Columns)
            const prec = controlRecord2(flds)
            const data = await MachshevetClient.RecordData(rt, prec, undefined, undefined, undefined, SettingGroup.EditColumns, signal)

            setState(prev => ({
                ...prev!, Records: prev!.Records!.map(rd => {
                    if (rd.Index === changeRow) {
                        rd.Fields = data.Records[0].Fields// newdata
                        return rd
                    }
                    else
                        return rd
                })
            }))

        }

    }, [changeFlag])

    const cmds = state && prioritizeCommands(ctx, commands)

    async function docmd(c: ColumnData2) {
        c.reloader = getSample
        await doCommand(c, 0, gridProps, app, ctx)
    }
    function setNewRow(newRow: LiteRecordData) {
        newRow.IsDeleted = false
        const idxs = state!.Records.map(x => x.Index)
        const newidx = idxs.length ? Math.max(...idxs) + 1 : 0
        newRow.Index = newidx
        setState(prev => ({ ...prev!, Records: prev!.Records.concat(newRow) }))
    }

    const relevrecs = state?.Records.filter(x => !x.IsDeleted).sortBy(x => x.Index)

    return <div style={{ display: 'flex', overflowX: "auto", gap: "4ch" }}>
        <div style={{ overflowY: "auto", flexGrow: 1 }}>
            <table style={{ display: "block", overflowY: "auto" }}>
                <thead>
                    <th />{state?.Columns.filter(x => x.Visible !== false).map(x => <th key={x.Name}>{x.LocalName}</th>)}<th />
                </thead>
                <tbody>
                    {relevrecs?.map(x => {
                        const flds = getControlProps3(x, state!.Columns)
                        const flds2 = flds.filter(x => x.memberData && x.memberData.Visible !== false)
                        return <tr key={x.Index} onContextMenu={e => {
                            ctx.showContextMenu!(e, docmd, cmds)
                        }}><td>{x.Index}</td>{flds2.map(y => {
                            y.editPage = true
                            y.mainRecordType = rt
                            y.onChange = async (v) => {
                                const newrow = flds.map(z => z.Key === y.Key ? { ...z, Value: v, Text: v } : z)
                                setState(prev => ({ ...prev!, Records: prev!.Records.map(rd => rd.Index === x.Index ? { ...rd, Fields: newrow } : rd) }))
                                setChangeRow(x.Index)
                                setChangeFlag(prev => prev + 1)
                            }

                            return <td key={y.Key} id={"Test_Control_" + y.memberData.Name}><Control field={y} /></td>
                        })}
                            <td>
                                {x.Fields.map(x => x as ControlProps2).filter(y => y.ErrorText).map(y => <span key={y.Key} style={{ color: "red", whiteSpace: "nowrap" }}>{y.ErrorRecordID ? <NavLink recordType={x.RecordType} recordID={y.ErrorRecordID} >{y.ErrorText}</NavLink> : <span>{y.memberData!.LocalName + " " + y.ErrorText}</span>}</span>)}
                                <Icon name="Delete" onClick={() => {
                                    setState(prev => ({
                                        ...prev!, Records: prev!.Records.map(y => {
                                            const rw = y
                                            if (rw.Index === x.Index) {
                                                rw.IsDeleted = true
                                            }
                                            return rw
                                        })
                                    }))
                                }} /></td>
                        </tr>
                    }
                    )}
                </tbody>
            </table>
            <div style={{ display: "flex" }}>
                <Icon name='Add' onClick={() => {
                    const newrow = { ...state!.Records[0] }
                    setNewRow(newrow)
                }} />
                <Icon name='Copy' onClick={() => {
                    const newrow = { ...state!.Records[state!.Records.length - 1] }
                    setNewRow(newrow)
                }} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <SmartButton onClick={async () => {
                    const recs = state!.Records.filter(x => !x.IsDeleted).map(x => {
                        const cprops = getControlProps2(x.Fields, state!.Columns).filter(x => x.memberData)
                        return controlRecord2(cprops)
                    })
                    const newids = await MachshevetClient.Insert(rt, recs)
                    const cdfilt = new ColumnData()
                    cdfilt.Name = "ID"
                    cdfilt.IDFilter = newids.join()
                    cdfilt.AutoFilterList = undefined
                    setGridProps(prev => ({ ...prev, Filters: [cdfilt] }))
                    //setPreFilter({ ["ID"]: newids.join() })
                    const samp = state!.Records[0]
                    setState(prev => ({ ...prev!, Records: [samp] }))
                }}>{ctx.localized("Save")}</SmartButton>
            </div>
            <ListView gridProps={{ ...gridProps, SettingGroup: SettingGroup.Columns }} showTools={false} saveState={false} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Icon name="Menu" onClick={() => setHamburgerShown(!hamburgerShown)} />
            {hamburgerShown && <HamburgerMenu onCommand={docmd} items={cmds!} />}
        </div>
    </div>
}
