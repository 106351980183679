//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { CSSProperties, FC, useContext, useEffect, useState } from "react"
import { MachshevetClient, LiteRecordData, RecordsData } from "./Declarations"
import { SmartCheckBox, SmartCount, SmartRow, useInterval } from "./misc"
import { ColumnHeader } from "./Column"
import { Control } from "./Control"
import { numberColor, redirect, parseDate, UtcDate, adjustColor, canEdit, getControlProps3, selectedColor } from "./globals"
import { AppContext, MainContext, TableBodyProps } from "./styles"

export const DataGrid: FC<TableBodyProps> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)!
    const [selectedRecordIndex, setSelectedRecordIndex] = useState(0)
    const [editCols, setEditCols] = useState<string[]>([])
    const [animationMode, setAnimationMode] = useState(false)
    const [records, setRecords] = useState<RecordsData>()


    useEffect(() => {
        setAnimationMode(true)
        setRecords(props.records)
    }, [props.records])

    useEffect(() => {
        // setTimeout(() => setAnimationMode(false), 500)
        setAnimationMode(false)
    }, [records])

    const topstyle: CSSProperties = { position: "sticky", top: 0, backgroundColor: "white", whiteSpace: "nowrap", padding: 4 }
    const partstyle: CSSProperties = { borderWidth: 1, borderStyle: "solid", borderColor: numberColor(ctx.data.SecondaryColor), borderRadius: 6 }//the padding ruins the sticky footers on the bottom
    const sortcols = records?.Columns.sortBy(x => x.Position)//here we have all the filtering/sorting info
    const sCell: CSSProperties = { padding: ".5ch 1ch" }
    const state = props.gridProps

    //const footcellstyle: CSSProperties = { position: "sticky", bottom: 0, backgroundColor: adjustColor(ctx.data.PrimaryColor!, .7), padding: ".2vmax", borderSpacing: 0 }
    const footcellstyle: CSSProperties = { backgroundColor: adjustColor(ctx.data.PrimaryColor!, .7), padding: ".2vmax", borderSpacing: 0 }

    function getMultiKeys(toIndex: number) {
        const arr = records!.Records.filter((r, idx) => {
            if ((idx >= selectedRecordIndex && idx <= toIndex) || (idx <= selectedRecordIndex && idx >= toIndex)) return true
            return false
        })
        return arr
    }

    return <table id="TestGridTable" tabIndex={1} style={{ ...partstyle, display: "block", whiteSpace: "nowrap", flexGrow: 1, flexBasis: 0, overflowY: "auto", borderCollapse: 'collapse' }}
        onContextMenu={e => props.setMenuField!(e)}>
        <thead style={{ position: "sticky", top: 0 }}>
            <tr id="TestGridHeader" style={{ backgroundColor: "white" }} >
                <th style={topstyle}></th>
                {props.showTools && <th style={topstyle}>
                    <input type="checkbox" id="TestSelectAll" onClick={async e => {
                        const ischecked = e.currentTarget.checked
                        if (ischecked) {
                            const data = await MachshevetClient.GetAllIDs(state!)
                            const recs = data.map(x => {
                                const y = new LiteRecordData()
                                y.RecordType = records?.RecordType// props.listType
                                y.RecordID = x
                                y.RecordKey = x.toString()
                                return y
                            })
                            props.setSelected!(recs, true, true)
                        } else props.setSelected!([], false, true);
                    }} />
                    <SmartCount testname="SelectCount" Number={state?.RecordKeys?.size} />
                </th>}
                {sortcols && sortcols.map(x => <ColumnHeader key={x.ColumnKey} column={x} gridProps={state!} fullPage={props.fullPage!} showTools={props.showTools} refresher={props.refreshData!} commands={props.commands?.filter(x => x.NeedsRecord === false && x.NeedsField)}
                    stateSetter={y => {
                        const newgp = state!
                        if (!newgp.Filters) newgp.Filters = []
                        newgp.Filters = newgp.Filters.map(z => z.ColumnKey === x.ColumnKey ? y : z)
                        if (!newgp.Filters.some(z => z.ColumnKey === y.ColumnKey)) newgp.Filters.push(y)
                        props.onPropsChanged!(newgp)
                    }}
                    editModeToggler={() => {                       
                        setEditCols(prev => {
                            const newState = prev.includes(x.Name!)
                                ? prev.filter(z => z !== x.Name)
                                : [...prev, x.Name!];
                            console.log(newState); // Check the updated state
                            return newState;
                        });

                    }}
                />)}
            </tr>
        </thead>
        <tbody>
            {
                records?.Records && records.Records.map((rowData, i) => {
                    const recid = rowData.RecordID!
                    const rectyp = rowData.RecordType!
                    let backclr = numberColor(rowData.BackColor?.Value)
                    let isnew = false
                    const reckey = rowData.RecordKey || (rectyp + '.' + recid + '.' + i)
                    const isSelected = state?.RecordKeys?.has(reckey) || false
                    if (isSelected) backclr = selectedColor(ctx)
                    if (rowData.AddedOn) {
                        const addon = parseDate(rowData.AddedOn.toString())!
                        const added2 = addon.getTime() - (props.timeDiff! * 1000)
                        const utcnow = UtcDate(new Date())
                        const datediff = (utcnow.getTime() - added2) / 1000
                        isnew = datediff < 9
                        if (isnew) backclr = "aqua"
                    }
                    const rowstyl: CSSProperties = { backgroundColor: backclr, color: numberColor(rowData.ForeColor), borderBottom: "solid 1px", borderBottomColor: adjustColor(ctx.data.SecondaryColor!, .7) }
                    if (props.oldKeys && animationMode && !props.oldKeys.includes(rowData.RecordKey!)) {
                        rowstyl.opacity = .1
                        //rowstyl.height = 0
                    }
                    const visflds = getControlProps3(rowData, records?.Columns!).sortBy(x => x.memberData.Position)
                    return <SmartRow id={"TestRow" + i} draggable key={reckey} title={rowData.BackColor?.Key} onDragOver={e => e.preventDefault()} style={rowstyl}
                        onDoubleClick={e => {
                            props.onRowDoubleClick ? props.onRowDoubleClick(rowData) : redirect(undefined, rectyp, recid, !e.ctrlKey)
                        }}
                        onClick={e => {
                            if (rowData.RecordKey) {
                                const shiftKey = e.shiftKey
                                props.onRowClicked && props.onRowClicked(recid, rowData.RecordName!)
                                if (shiftKey) {
                                    const selctdKeys = getMultiKeys(i)
                                    props.setSelected!(selctdKeys, true, true)
                                }
                                else {
                                    props.setSelected!([rowData], true, true)
                                    setSelectedRecordIndex(i)
                                }
                            }

                        }}
                        onContextMenu={e => {
                            if (!isSelected) {
                                props.setSelected!([rowData], true, false)
                                setSelectedRecordIndex(i)
                            }
                            props.setMenuField!(e)
                        }}>
                        <td><span style={{ color: numberColor(ctx.data.SecondaryColor) }}>{i + 1}</span></td>
                        {props.showTools && <td
                            onClick={e => {
                                e.stopPropagation()
                                const shiftKey = (e.nativeEvent as unknown as PointerEvent).shiftKey
                                let recs: LiteRecordData[]
                                if (shiftKey && selectedRecordIndex !== undefined && !isSelected) recs = getMultiKeys(i)
                                else recs = [rowData]
                                props.setSelected!(recs, !isSelected, false)
                                if (!isSelected && !(shiftKey && !state?.RecordKeys !== undefined)) {
                                    props.setSelected!([rowData], true, false)
                                    setSelectedRecordIndex(i)
                                }
                                return false
                            }} >
                            <SmartCheckBox id="TestSelectRow" checked={isSelected} />
                        </td>}
                        {visflds.map(x => {
                            const stl = { ...sCell }
                            x.recordID = rowData.RecordID
                            x.recordKey = rowData.RecordKey
                            //x.recordType = rowData.RecordType
                            x.reloader = () => props.refreshData && props.refreshData()
                            x.editMode = editCols.includes(x.memberData.Name!)
                            //x.gridTerm = state?.Term
                            //if (x.memberData.EditMode || editCols.includes(x.memberData.Name!)) {
                            //   // x.memberData.EditMode = true
                            //}
                            x.gridProps = state
                            if (x.memberData.BackColor) stl.backgroundColor = numberColor(x.memberData.BackColor)
                            if (x.BackColor) stl.backgroundColor = numberColor(x.BackColor)
                            if (x.memberData.Name === app.menuField?.memberData.Name) {
                                stl.borderWidth = 1
                                stl.borderColor = numberColor(ctx.data.PrimaryColor)
                                stl.borderStyle = "dotted"
                            }
                            if (x.memberData.Editable && !x.style) x.style = {}

                            return <td key={x.Key} id={"Test_Control_" + x.memberData.Name} style={stl} onContextMenu={e => {
                                if (!isSelected) {
                                    props.setSelected!([rowData], true, false)
                                    setSelectedRecordIndex(i)
                                }
                                props.setMenuField!(e, x)
                            }
                            } ><Control field={{
                                ...x, onChange: async () => { }, onBlur: async e => {
                                    if (canEdit(x)) {//cause onblur also happens on noeditable navlinks
                                        const fldnm = x.memberData.IDFieldName || x.memberData.Name!
                                        const obj: { [k: string]: any } = {}
                                        obj[fldnm] = e
                                        obj.ID = rowData.RecordID
                                        await MachshevetClient.SaveRecord(rowData.RecordType!, obj)
                                        props.refreshData!()
                                    }
                                }
                            }} /></td>
                        })}
                    </SmartRow>
                })}
        </tbody>
        {
            props.totals && props.totals.Sums?.length > 0 && <tfoot style={{ position: "sticky", bottom: 0 }}>
                <tr>
                    <td style={footcellstyle} />
                    <td style={footcellstyle} />
                    {sortcols && sortcols.map(x => {
                        const ttl = props.totals!.Sums.find(y => y.Key === x.Name)
                        const sym = records?.Records?.flatMap(y => y.Fields).find(y => y.Key === x.ColumnKey)?.Coin
                        return <td id={"TestAggregate_" + x.Name} key={x.ColumnKey} style={footcellstyle}
                        >{ttl && <SmartCount Number={ttl?.Value} fieldType={x.FieldType} coinSymbol={sym} interval={x.DateInterval} />}</td>
                    })}
                </tr>
            </tfoot>
        }
    </table>
}