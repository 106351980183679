//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

export function queryVals(obj: any, inclueEmpties = false): string {
    let query: string | undefined
    for (let k in obj) {
        let value = obj[k]
        if (value || inclueEmpties) {
            if (!value) value = ''
            const argument = `${encodeURIComponent(k)}=${encodeURIComponent(value)}`
            if (query) query = query + '&' + argument
            else query = argument
        }
    }
    return query || ''
}

export async function fetchPlus(input: RequestInfo, init?: RequestInit) {
    //try {
    const rsp = await fetch(input, init)
    if (rsp.status != 200) {
        let msg = await rsp.text()
        if (msg.startsWith("<")) {
            const parser = new DOMParser()
            //const doc = parser.parseFromString(msg, "text/xml")
            const doc = parser.parseFromString(msg, "text/html")
            const ttl = doc.getElementsByTagName('title')[0]
            msg = ttl.innerHTML
        }
        throw new Error(msg)
    }
    return rsp
}

//type OutputAllTypes<T> = {
//    [K in keyof T]: T[K];
//};

export async function fetchJson<T>(input: RequestInfo, init?: RequestInit) {
    const rsp = await fetchPlus(input, init)
    const json = await rsp.json()
    //return js as Promise<T>


    //interface PropertyMetadata {
    //    key: string;
    //    isBoolean: boolean;
    //}

    //const propertyMetadata: PropertyMetadata[] = []; // Cache property metadata

    //const getIsBoolean = (obj: any, key: string): boolean => {
    //    if (propertyMetadata.length === 0) { // Check cache on first call
    //        for (const prop in obj) {
    //            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
    //                propertyMetadata.push({ key: prop, isBoolean: typeof obj[prop] === 'boolean' });
    //            }
    //        }
    //    }

    //    const foundMetadata = propertyMetadata.find(m => m.key === key);
    //    return foundMetadata?.isBoolean ?? false; // Return false if not found
    //}
    const getIsBoolean = (obj: any, key: string): boolean => {
        // Leverage keyof operator to access type information
        //const dummy=new T()
        //const propType = T[key]
        //return typeof propType === 'boolean';
        return false
    };


    const convertBooleans = (obj: any): T => {
        if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
            return obj; // Don't modify lists or null/undefined values
        }

        const transformedObj: any = {} as T;
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                let value = obj[key]

                if (value === 0 || value === 1) {
                    if (key === "ShowPreview") {
                        const a=1
                    }
                    if (getIsBoolean(obj, key) && typeof value === 'number') {
                        value = value===1
                    }
                }


                transformedObj[key]=value
                //transformedObj[key] = getIsBoolean(obj, key) && typeof value === 'number' && (value === 0 || value === 1)
                //    ? value === 1 // Convert only booleans (0 or 1) for known boolean properties
                //    : convertBooleans(value); // Recursively handle nested objects
            }
        }
        return transformedObj;
    };



    const ret = convertBooleans(json)
    return ret
}
