//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useEffect, useRef, useContext, CSSProperties } from 'react'
import { HamburgerMenu, Avatar2, useInterval, SmartButton, useEffect2, ControlHolder, Icon, useDebuggableState, NavLink } from './misc'
import { ColumnData, ControlProps, DataTypes, FieldTypes, GridProps, MachshevetClient, SettingGroup, RecordsData, LiteRecordData } from './Declarations'
import { controlRecord2, defaultGridProps, groupBy, numberColor, RecordFormProps, showConfirm, getControlProps3, objectDiffs, dictionaryValues } from './globals'
import { AppContext, ColumnData2, ControlProps2, doCommand, MainContext, prioritizeCommands } from './styles'
import { useParams } from 'react-router-dom'

export const RecordForm: FC<RecordFormProps> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [state, setState] = useState<RecordsData>()
    const [commands, setCommands] = useState<ColumnData[]>([])
    const [menuShown, setMenuShown] = useState(false)
    const [fieldChanged, setFieldChanged] = useState(0)
    const [dirty, setDirty] = useState(false)
    const [changeField, setChangeField] = useState('')
    const [subChangeField, setSubChangeField] = useState<string>()
    const [subChangeIndex, setSubChangeIndex] = useState<number>()
    const [gridProps, setGridProps] = useDebuggableState<GridProps>()
    const [files, setFiles] = useState<{ Key: string, Value: File }[]>([])
    const [alerts, setAlerts] = useState<{ [index: number]: string }>()
    const aborter = useRef(new AbortController())
    const { RecordType, ID } = useParams()


    useEffect(() => {
        const set = ID ? new Set([ID]) : new Set([])
        setGridProps(prev => ({ ...prev!, RecordType: RecordType, RecordKeys: set, SettingGroup: SettingGroup.EditFields, RecordValues: { ID }, Filters: [] }))
    }, [RecordType, ID])
    useEffect2(async () => {
        await loadRecord(true)
    }, [gridProps])
    useEffect2(async () => {
        if (gridProps && !commands.length) {
            const cmnds = await MachshevetClient.Commands(gridProps)
            setCommands(cmnds)
        }
    }, [gridProps?.RecordValues])
    useEffect(() => {
        //setState(props.record)
        const recs = props.record
        if (recs && gridProps?.RecordType !== recs.RecordType) setGridProps(prev => ({ ...prev!, RecordType: recs.RecordType }))
        const rec = recs?.Records[0]
        //if (rec) setGridProps(prev => ({ ...prev, RecordType: recs.RecordType, RecordKeys: new Set(['' + rec.RecordID]) }))
        if (rec) {
            //if (!isEqual(state, recs)) setGridProps(prev => ({ ...prev, RecordType: recs.RecordType, RecordKeys: new Set(['' + rec.RecordID]) }))
            if (gridProps?.RecordType !== recs.RecordType || [...gridProps!.RecordKeys][0] !== rec.RecordID.toString()) setGridProps(prev => ({ ...prev!, RecordType: recs.RecordType, RecordKeys: new Set(['' + rec.RecordID]) }))
            //if (rec?.Fields) setState(recs)
            const recchanges = objectDiffs(state, recs)
            if (recchanges.length) {
                const dmy = objectDiffs(state, recs)
                setState(recs)
            }
        }
    }, [props.record])
    useEffect2(async () => {
        //await firstLoad()
        window.addEventListener('beforeunload', onBeforeUnload)
        return () => { window.removeEventListener('beforeunload', onBeforeUnload) }
    }, [])

    useEffect(() => {
        if (props.setSaveHandler) props.setSaveHandler(() => save)
    }, [state])

    useEffect2(async () => {
        if (recrd?.Fields.length) {
            aborter.current.abort()
            aborter.current = new AbortController()
            const rec = getRecord()
            const data = await MachshevetClient.RecordData(rectyp!, rec, changeField, subChangeIndex, subChangeField, undefined, aborter.current.signal)
            setState(data)
            //setGridProps(prev => ({ ...prev!, RecordValues: newvals }))
        }
    }, [fieldChanged, subChangeField, subChangeIndex])

    useInterval(async () => {
        if (ctx.docActive() && !dirty && recrd?.RecordID) {
            await loadRecord();
        }
    }, 9000, true)

    const pagid = ID ? +ID : undefined
    const recrd = state?.Records[0]
    //const recrd = state?.Records?[0]
    const rcrdid = pagid || recrd?.RecordID
    const rectyp = RecordType || state?.RecordType

    const flds = state?.Columns?.length ? getControlProps3(recrd!, state.Columns) : undefined

    function setIsDirty(isDir: boolean) {
        setDirty(isDir)
        props.onDirty && props.onDirty(isDir)
    }
    async function loadRecord(firstTime?: boolean) {
        if (gridProps?.RecordType) {
            const recid = +[...gridProps.RecordKeys][0]
            let kvs = props.presetValues
            if (!props.presetValues) kvs = getRecord()//specially for new records, shouldnt lose data when choosing columns/reload
            const rec = await MachshevetClient.PageRecord(gridProps.RecordType, recid, kvs, props.addingRecord, props.addingRecordType, props.addingField, firstTime)
            setRecord(rec)

            const alrts = await MachshevetClient.GetRecordAlerts(gridProps.RecordType, recid)
            setAlerts(alrts)

        }
    }
    function setRecord(record: RecordsData) {
        setState(record)
        props.onRefreshed && props.onRefreshed(record)
    }

    function onBeforeUnload(e: BeforeUnloadEvent) {
        if (dirty) {
            e.preventDefault()
            e.returnValue = ''
            return
        }
        delete e['returnValue']
    }
    function setFieldValue(fieldName: string, newValue: any, subFieldName?: string, subFieldIndex?: number) {
        setIsDirty(true)
        const isfil = newValue instanceof File
        setState(prev => {
            const nval = isfil ? null : newValue
            const ret = { ...prev!, Records: [{ ...prev!.Records[0], Fields: prev!.Records[0]!.Fields.map(x => (x as ControlProps2).memberData.Name === fieldName ? { ...x, Value: nval, Text: nval } : x) }] }
            if (isfil) {
                setFiles(prev => {
                    const ret = prev
                    ret.push({ Key: fieldName, Value: newValue })
                    return ret
                })
            }
            return ret
        })
        var a = fieldChanged + 1
        setFieldChanged(a)
        setChangeField(fieldName)
        setSubChangeField(subFieldName)
        setSubChangeIndex(subFieldIndex)
    }

    async function handleFieldChange(value: any, field: ControlProps, newrow?: LiteRecordData, subPropertyName?: string, idx?: number) {
        if (state) {
            const fld2 = field as ControlProps2
            if (subPropertyName && (idx != undefined)) {
                const newrows: RecordsData = recrd!.Fields.find(x => (x as ControlProps2).memberData.Name === subPropertyName)!.Value
                const nrow = newrows.Records[idx!]
                nrow.Fields = nrow.Fields.map(x => x.Key === field.Key ? { ...x, Value: value, Text: value } : x)
                newrows.Records[idx!] = nrow
                setFieldValue(subPropertyName!, newrows, fld2.memberData.Name, idx)
            } else {
                const fieldName = fld2.memberData.Name!
                if (newrow) {
                    const rows: RecordsData = recrd!.Fields.find(x => (x as ControlProps2).memberData.Name === subPropertyName)!.Value
                    rows.Records.push(newrow)
                    value = rows//  a
                }
                setFieldValue(fieldName, value)
            }
        }
    }
    function getRecord() {
        if (!flds) return undefined

        files.forEach(x => {
            const newfld = new ControlProps2()
            const newname = x.Key + '_File'
            newfld.Value = x.Value
            newfld.memberData.Name = newname
            newfld.memberData.Editable = true
            flds.push(newfld)
        })


        const prec = controlRecord2(flds, rcrdid)
        prec["TempBulkField"] = recrd!.TempBulkField
        prec["TempBulkIDs"] = recrd!.TempBulkIDs
        prec["TempParentRecordType"] = recrd!.TempParentRecordType
        prec["ChildKeyField"] = recrd!.ChildKeyField
        prec["TempCommand"] = state?.TempCommand
        return prec
    }

    async function save() {
        const warns = flds!.filter(x => x.WarningText)
        if (warns.length) {
            const wrnmsg = warns.map(x => x.memberData!.LocalName + ": " + x.WarningText).join("\n")
            const cnf = showConfirm(wrnmsg + "\n\n" + ctx.localized("SaveAnyway") + "?")
            if (!cnf) return
        }
        const rec = getRecord()
        if (recrd?.TempBulkIDs) {
            await MachshevetClient.SaveBulk(rectyp!, rec)
            props.onSaved && props.onSaved(undefined, rcrdid)
            return undefined
        } else {
            const obj = getRecord()
            const record = await MachshevetClient.SaveRecord(rectyp!, obj)
            setIsDirty(false)
            const id = record.Records[0].RecordID!
            props.onSaved && props.onSaved(id, rcrdid)
            setRecord(record)
            return id
        }
    }
    const groupedFields = flds && groupBy(flds.filter(x => x.memberData && x.memberData.Visible !== false), x => x.memberData!.Group!)
    //const validerrs = recrd?.Fields.filter(x => x.ErrorText)
    const validerrs = flds?.filter(x => x.ErrorText) || []
    //const flderr = recrd?.Fields.filter(x => x.ErrorText)[0]
    //const haserr = !dirty || flderr != undefined
    const haserr = !dirty || validerrs.length > 0
    const fldcommands = prioritizeCommands(ctx, commands.filter(x => x.NeedsField && !x.NeedsReport && !x.NeedsList).map(x => {
        if (x.FieldPicker) x.HasPickList = false
        return x
    }))
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const PublicUrl = urlParams.get('')
    //const gp = defaultGridProps()
    //gp.SettingGroup = SettingGroup.EditFields
    //gp.RecordType = rectyp
    //if (rcrdid) gp.RecordKeys = new Set([rcrdid.toString()])
    //gp.RecordValues = getRecord()


    let topbuttons: ColumnData2[] = []
    if (state) {
        topbuttons = commands.filter(x => !x.NeedsField).sortBy(x => -x.Uses).slice(0, 6).map(x => x as ColumnData2)
        if (state.QuickAdds) {
            const qads = state.QuickAdds.map(x => {
                const cp = new ColumnData2()
                cp.Name = 'Add'
                cp.LocalName = '+' + ctx.localized(x.Key)
                cp.commandParams = [x.Key + '.' + x.Value]
                return cp
            })
            topbuttons = topbuttons.concat(qads)
        }
    }

    if (gridProps) gridProps.RecordValues = getRecord()

    const fldStl: CSSProperties = { display: "flex", flexWrap: "wrap", columnGap: "2ch", rowGap: "1ch" }

    //const alrts = recrd?.Alerts// || []


    return <div id={"TestRecord_" + rectyp} style={{ display: "flex", overflowY: 'auto', flexGrow: 1 }}>


        <div className="div2" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: "1ch", overflowX: 'auto' }}>
            {state && commands.length > 0 && gridProps && <div id="TestCommands" className="div3" style={{ display: 'flex', gap: 7 }}>{topbuttons.map(x => <Avatar2 {...x} key={x.Name! + (x.commandParams || '')} gridProps={{ ...gridProps, Member: x.Name }} reloader={async () => {
                if (x.Name === "Delete" && !props.isPopup)
                    window.history.back()
                else
                    await loadRecord()
            }} params={x.commandParams} style={{ borderWidth: x.commandParams?.length ? 3 : "" }} />)}</div>}


            {alerts && <div style={{ color: 'red' }}> {dictionaryValues(alerts).map(x => <NavLink reportID={x.Key}>🚩 {x.Value}</NavLink>)} </div>}

            {groupedFields?.size && <div id="TestRecordFields" style={{ overflowY: 'auto', flexGrow: 1 }}>
                {groupedFields && [...groupedFields.keys()].map(grp => {
                    const kGroup = grp || ctx.localized('General')
                    let allflds = groupedFields.get(grp)!
                    allflds = allflds.map(x => {
                        x.onChange = async (v, f, nr, i) => await handleFieldChange(v, f || x, nr, x.memberData.Name, i)
                        x.reloader = loadRecord
                        x.editPage = true
                        x.recordID = rcrdid
                        x.items = fldcommands
                        x.recordType = recrd?.RecordType
                        x.mainRecordType = recrd?.RecordType
                        x.gridProps = gridProps
                        return x
                    })
                    const talls = allflds?.filter(x => {
                        const m = x.memberData
                        return m.DataType === DataTypes.List || (m.DataType === DataTypes.Bytes && m.FieldType !== FieldTypes.Audio) || m.Multiline || (m.FieldType && [FieldTypes.Html, FieldTypes.Audio, FieldTypes.Svg, FieldTypes.Json].includes(m.FieldType))
                    })
                    const tallnames = talls.map(x => x.memberData.Name!)
                    const flds = allflds?.filter(x => !x.memberData.IsCommand && !tallnames.includes(x.memberData.Name!))

                    return <fieldset key={kGroup} style={{ display: 'flex', flexDirection: 'column', rowGap: "5vh", borderColor: numberColor(ctx.data.SecondaryColor), borderRadius: 5, borderWidth: 1 }}>
                        <legend style={{ fontSize: "120%", fontWeight: 'bold', color: numberColor(ctx.data.SecondaryColor) }}>{kGroup}</legend>
                        <div style={fldStl}>
                            {flds!.sortBy(x => x.memberData.Position).map(x => <ControlHolder key={x.Key} {...x} />)}
                        </div>
                        {talls.length > 0 && <div style={{ display: "flex", flexWrap: "wrap", gap: "5ch" }}>
                            {talls.sortBy(x => x.memberData!.Position).map(x => <ControlHolder key={x.Key} {...x} />)}
                        </div>}
                    </fieldset>
                })}
            </div>}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: 'red', fontSize: "90%", alignSelf: 'start' }}> {validerrs.map(x => <div key={x.memberData.Name}><span>· {x.memberData.LocalName}</span> <span> {x.ErrorText}</span> </div>)}</div>
                {!props.setSaveHandler && <SmartButton testName="Save" disabled={haserr} onClick={save} style={{ alignSelf: 'center' }}>{ctx.localized('Save')}</SmartButton>}
            </div>
            {
                PublicUrl && <div style={{ textAlign: "center" }}><a style={{ fontSize: "130%" }} href={PublicUrl}>{ctx.localized("Back")}</a></div>
            }
        </div>

        <div style={{ padding: 7, display: 'flex', flexDirection: 'column' }}>
            <Icon name={menuShown ? "HideMenu" : "ShowMenu"} onClick={async () => setMenuShown(!menuShown)} />
            {menuShown && <HamburgerMenu gridProps={gridProps} onCommand={async c => {
                const gp = defaultGridProps(rcrdid)
                gp.SettingGroup = SettingGroup.EditFields
                gp.RecordType = rectyp
                c.reloader = async () => {
                    if (c.Name === 'Delete') {
                        props.onSaved && props.onSaved(rcrdid)
                        app.closeRecord() //if deleted on popup
                        window.history.back() //if deleted in edit page
                    } else {
                        await loadRecord()
                    }
                }
                await doCommand(c, 0, gp, app, ctx)
                setMenuShown(false)
            }} items={commands.filter(x => !x.NeedsField).map(x => {
                const cd = x as ColumnData2
                cd.recordType = rectyp
                return cd
            })} />}

        </div>
    </div >
}
