//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useState, FC, CSSProperties, HTMLProps } from "react"
import { UtcDate, numberColor, range, GetMonthName, hebrewDate, numberHebrew } from "./globals"
import { SmartButton, SmartDiv } from "./misc"
import { MainContext } from "./styles"

export const MiniCal: FC<HTMLProps<HTMLDivElement> & { selectedDate?: Date, showTime: boolean, onSelected: (date: Date) => void }> = props => {
    const ctx = useContext(MainContext)
    const [value, setValue] = useState(props.selectedDate)//selected date
    const [state, setState] = useState(props.selectedDate || new Date())//navigation date

    function setSelected(date: Date) {
        setState(date)
        setValue(date)
        props.onSelected(date)
    }

    function setMinutes(minute: number) {
        const ndate = value!.setUTCMinutes(minute)
        const dt = new Date(ndate)
        setSelected(dt)
    }
    function setHour(hour: number) {
        const ndate = value!.setUTCHours(hour)
        const dt = new Date(ndate)
        setSelected(dt)
    }

    function setStateNum(date: number) {
        const dt = new Date(date)
        setState(dt)
    }

    const selhour = value?.getUTCHours()
    const stlhead: CSSProperties = { backgroundColor: numberColor(ctx.data.PrimaryColor), color: "white" }
    const yer = state.getFullYear()

    const yrs = range(yer - 10, yer + 10);
    const btnstyle: CSSProperties = { padding: 1 }
    return <div {...props} style={{ textAlign: 'center', display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer - 10))} >-10</SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer - 1))} >{ctx.data.IsRtl ? "<<" : ">>"}</SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setMonth(state.getMonth() - 1))} >{ctx.data.IsRtl ? "<" : ">"}</SmartButton>
            <select style={btnstyle} tabIndex={-1} value={state.getMonth()} onChange={e => setState(new Date(yer, +e.currentTarget.value, 1))}>
                {range(0, 11).map(x => {
                    const txt = (x + 1) + ' | ' + GetMonthName(ctx, x)
                    return <option key={x} value={x}>{txt}</option>
                })
                }
            </select>
            <select style={btnstyle} tabIndex={-1} value={yer} onChange={e => setState(new Date(+e.currentTarget.value, state.getMonth(), state.getDate()))} >
                {yrs.map(x => <option value={x} key={x}>{x}</option>)}
            </select>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setMonth(state.getMonth() + 1))} >{ctx.data.IsRtl ? ">" : "<"}</SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer + 1))} >{ctx.data.IsRtl ? ">>" : "<<"}</SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setSelected(UtcDate(new Date()))}> {ctx.localized("Now")} </SmartButton>
            <SmartButton style={btnstyle} tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer + 10))} >+10</SmartButton>
        </div>
        <div style={{ display: "flex", overflowY: "auto", fontSize: "80%" }}>
            <table style={{ flexGrow: 1 }}>
                <thead>
                    <tr style={stlhead}>
                        {range(0, 6).map(x => <th key={x} >{x + 1}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {range(0, 5).map(week => {
                        return <tr key={week}>
                            {range(0, 6).map(i => {
                                const firstDayOfMonth = new Date(yer, state.getMonth(), 1)
                                const monthday = 1 - firstDayOfMonth.getDay() + week * 7 + i
                                const day = new Date(Date.UTC(yer, state.getMonth(), monthday))
                                const stl: CSSProperties = { display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }
                                if (value?.getUTCDate() === monthday && value?.getUTCMonth() === state.getMonth()) stl.backgroundColor = "orange"
                                if (day.getMonth() !== state.getMonth()) stl.color = "lightgray"
                                return <td key={i} style={{ padding: 5, height: 1 }}>
                                    <SmartDiv id={"TestDay" + monthday} style={stl} onMouseDown={() => setSelected(day)}>
                                        <span>{day.getDate()}</span><span style={{ fontSize: "90%", textAlign: "end" }}>{numberHebrew(hebrewDate(day).day)} </span>
                                    </SmartDiv>
                                </td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
            {props.showTime && <div style={{ display: "flex", gap: 3, flexGrow: 1 }}>
                <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                    <div style={stlhead}>{ctx.localized("Hour")}</div>
                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", flexGrow: 1, overflowY: "auto" }}>
                        {range(0, 23).map(x => {
                            const stl: CSSProperties = { padding: 1 }
                            if (x === selhour) stl.backgroundColor = "orange"
                            return <SmartDiv key={x} id={"TestHour" + x} style={stl} onMouseDown={() => setHour(x)}>{x}</SmartDiv>
                        })}
                    </div>
                </div>
                <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                    <div style={stlhead}>{ctx.localized("Minute")}</div>
                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", flexGrow: 1, overflowY: "auto" }}>
                        {range(0, 11).map(x => {
                            const stl: CSSProperties = { padding: 1 }
                            const min = x * 5
                            if (min === value?.getMinutes()) stl.backgroundColor = "orange"
                            return <SmartDiv key={x} style={stl} onMouseDown={() => setMinutes(min)}>{min}</SmartDiv>
                        })}
                    </div>
                </div>
            </div>}
        </div>
    </div >
}