//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext } from 'react'
import { FC, useState, useRef } from 'react'
import { MachshevetClient, SearchResultGroup } from './Declarations'
import { adjustColor, numberColor, redirect } from './globals'
import { Icon, NavLink, Preview, SmartDiv, SmartInput, useEffect2 } from './misc'
import { MainContext } from './styles'

export const GlobalSearch: FC<{ term?: string }> = props => {
    const ctx = useContext(MainContext)!
    const [results, setResults] = useState<SearchResultGroup[]>()
    const [selected, setSelected] = useState<[number, number]>()
    const [showResults, setShowResults] = useState(false)
    const [previewKey, setPreviewKey] = useState<string>()
    const abortController = useRef(new AbortController())
    const ref = useRef<HTMLInputElement>(null)

    useEffect2(async () => {
        if (props.term) {
            //if (!ref.current.foc)
            ref.current?.focus()
            setShowResults(true)
            if (props.term.length >= 2) {
                abortController.current.abort()
                abortController.current = new AbortController()
                const data = await MachshevetClient.GlobalSearch(props.term, abortController.current.signal)
                setResults(data)
            }
        } else {
            setShowResults(false)
            setResults([])
        }
    }, [props.term])

    function inputKeyDown(event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) {
        const key = event.key
        if (results && key === 'ArrowDown') {
            if (!selected) setSelected([0, 0])
            else {
                //if its the last one in group:
                if (results[selected[0]].List.length - 1 === selected[1]) {
                    //if its the last group:
                    if (results.length - 1 === selected[0])
                        setSelected([0, 0]);
                    else
                        setSelected([selected[0] + 1, 0]);
                }
                else setSelected([selected[0], selected[1] + 1]);
            }
        } else if (results && key === 'ArrowUp') {
            if (!selected) setSelected([results.length - 1, results[results.length - 1].List.length - 1]);
            else {
                //if its the first in this group:
                if (selected[1] === 0) {
                    //if its the first group:
                    if (selected[0] === 0)
                        setSelected([results.length - 1, results[results.length - 1].List.length - 1]);
                    else
                        setSelected([selected[0] - 1, results[selected[0] - 1].List.length - 1]);
                }
                else setSelected([selected[0], selected[1] - 1]);
            }
        }
        else if (results && selected && key === "Enter") {
            const res = results[selected[0]].List[selected[1]]
            redirect(res.RecordType == 'Report' ? res.RecordID : undefined, res.RecordType, res.RecordID)
            setShowResults(false)
            //setFocused(false)
            setSelected([0, 0])
        }
        else if (key === "Esc" || key === "Escape") {
            setShowResults(false)
            setResults([])
            setSelected([0, 0])
        }
    };

    return <div id="TestFixedGlobalSearch">
        <SmartInput id="TestGlobalSearch" ref={ref} type="search" onChange={e => ctx.setSearchTerm!(e.currentTarget.value)} onKeyDown={inputKeyDown} value={props.term} placeholder={ctx.localized('GlobalSearch') + "…"} />
        {showResults && <>
            {results ?
                <div id="TestGlobalSearchResults" style={{ backgroundColor: 'white', borderColor: numberColor(ctx.data.SecondaryColor), borderWidth: 1, borderStyle: 'solid', maxHeight: "60vh", position: 'absolute', borderRadius: 5, overflow: 'auto', zIndex: 2 }}>
                    {results && results.map((x, groupIndex) => <div key={x.Name}>
                        <div style={{ padding: 5, display: 'flex', alignItems: 'center', backgroundColor: adjustColor(ctx.data.SecondaryColor!, .7) }}>
                            <div style={{ fontWeight: 'bold', flexGrow: 1 }}>{x.LocalName}</div>
                            <div style={{ fontSize: 10, direction: 'ltr' }}>{x.List.length} / {x.Total}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 4 }}>
                            {x.List.map((x, resultIndex) => {
                                const ky = x.RecordType + '.' + x.RecordID
                                const _selected = selected && selected[0] === groupIndex && selected[1] === resultIndex
                                return <SmartDiv key={x.RecordID}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: _selected ? numberColor(ctx.data.SecondaryColor) : "unset", gap: 5 }}>
                                        <NavLink key={x.RecordID} style={{ display: "block" }} recordType={x.RecordType} reportID={x.RecordType == 'Report' ? x.RecordID : undefined} recordID={x.RecordID} onClick={() => {
                                            setResults([])
                                            setShowResults(false)
                                        }}>{x.Name}</NavLink><Icon name="Preview" onClick={() => {
                                            if (previewKey === ky) {
                                                setPreviewKey(undefined)
                                            } else setPreviewKey(ky)
                                        }} />
                                    </div>
                                    {previewKey === ky && <Preview recordType={x.RecordType!} id={x.RecordID!} miniMode={true} />}
                                </SmartDiv>
                            })}
                        </div>
                    </div>)}
                </div> :
                undefined}
        </>}
    </div>
}
