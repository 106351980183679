//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import { CSSProperties, ReactElement, ReactNode, useEffect, useState, useMemo, JSX } from "react"
import { useLocation } from "react-router-dom";
import { AppData, ColumnChoice, ColumnData, ControlProps, DataTypes, FileActions, GridProps, Intervals, JMonths, LiteRecordData, RecordsData, SettingGroup } from "./Declarations"
import { fetchJson, queryVals } from "./shared"
import { ColumnData2, CommandFile2, ControlProps2 } from "./styles";

declare global {
    interface Array<T> {
        sortBy: (generator: (value: T) => any[] | any) => T[]
        sum<K extends keyof T>(getKey: (item: T) => T[K]): number
        options: () => JSX.Element[]
    }
}

Array.prototype.sortBy = function <T>(this: Array<T>, sortkeysGenerator: (x: T) => any[] | any): Array<T> {
    this.sort((a, b) => {
        const sortkeysA = sortkeysGenerator(a);
        const sortkeysB = sortkeysGenerator(b);
        if (!(sortkeysA instanceof Array)) { return compare(sortkeysA, sortkeysB); }
        const length = sortkeysA.length;
        for (let i = 0; i < length; i++) {
            const compareResult = compare(sortkeysA[i], sortkeysB[i]);
            if (compareResult !== 0) { return compareResult; }
        }
        return 0;
    });
    //var d = dummyenum.Error
    return this;
}

Array.prototype.sum = function <T, K extends keyof T>(getKey: (item: T) => T[K]): number {
    return this.reduce((acc, item) => acc + Number(getKey(item)), 0);
}

export function groupBy<T>(list: Array<T>, keyGetter: (x: T) => string) {
    const ret = list.reduce((entryMap, e) => entryMap.set(keyGetter(e), [...entryMap.get(keyGetter(e)) || [], e]), new Map<string, T[]>());
    return ret
}

window.addEventListener('error', function (event) {
    alert(event.message)
})

export function isNumber(value?: unknown) {
    if (value === null) return false
    if (value === undefined) return false
    if (value === "") return false
    return !isNaN(Number(value))
}

window.addEventListener('unhandledrejection', function (event) {
    if (event.reason instanceof (DOMException)) {
        if (event.reason.name === "AbortError") return
    }
    if (event.reason instanceof (PromiseRejectionEvent)) {
        return
    }
    let msg = event.reason + ''
    const m = msg.toLowerCase()
    if (m.includes("failed to fetch") || m.includes("the resource cannot be found") || m.includes("failed to execute 'observe' on")) return
    msg = msg.replace(/<br>/g, "\n")
    msg = msg.replace(/<\/br>/g, "")
    alert(msg)
})

export type KeyValuePair<K, V> = { Key: K; Value: V }
export function KVPair<K, V>(Key: K, Value: V) {
    return { Key, Value }
}


export function getGlobalUrl(action: string, props?: any) {

    let dmn = leftCut(window.location.pathname, "/")
    dmn = dmn.replace("Backend/", "")
    let ret = (dmn ? "/" + dmn : "") + "/Global/" + action
    if (props) ret = ret + '?' + queryVals(props)
    return ret
}

export class headerItem {
    id = 0;
    count?: number;
    aggregation?: number;
    column?: ColumnData;
}

export function dePascalCase(input: string) {
    const words = input.replace(/([A-Z])/g, ' $1').trim().split(' ')
    return words.join(" ")
}

export function invokeFunction<T>(method: string, vals: Record<string, any>, usePost?: boolean, signal?: AbortSignal) {
    vals["PageUrl"] = window.location.href
    vals["PageTitle"] = window.document.title
    if (usePost) {
        const url = getGlobalUrl(method)
        const hfile = hasFile(vals)
        if (hfile) {
            const formData = new FormData()
            const fils = flatten(vals).filter(x => x[1] instanceof File)
            fils.forEach(x => {
                formData.append(x[0], x[1])
            })
            const js = myStringify(vals)
            formData.append('JsonInput', js)
            const ret = fetchJson<T>(url, {
                method: "POST",
                body: formData,
                signal
            })
            return ret
        }
        else {
            const jvals = myStringify(vals)
            const ret = fetchJson<T>(url, {
                method: "POST",
                body: jvals,
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            })
            return ret
        }
    }
    else {
        const url = getGlobalUrl(method, vals)
        return fetchJson<T>(url, { signal })
    }
}
export function keyboardSwitchedText(input: string): string {
    const cHeb = "/'קראטוןםפשדגכעיחלךףזסבהנמצתץ"
    const cEng = "qwertyuiopasdfghjkl;zxcvbnm,."
    let keys = cEng
    let vals = cHeb
    if ([...input].some(char => cHeb.includes(char))) {
        keys = cHeb
        vals = cEng
    }

    let result = input.toLowerCase()
    for (let i = 0; i < keys.length; i++) {
        const c = keys[i]
        const val = vals[i]
        const escapedChar = c === '.' ? '\\.' : c
        const regex = new RegExp(escapedChar, 'g')
        result = result.replace(regex, val)
    }
    return result
}

export function canEdit(props: ControlProps2) {
    return (props.memberData.Editable && props.editPage) || props.memberData.EditMode || props.editMode
}

export function testAttribs(props: ControlProps2) {
    return { id: testid(props), 'data-datatype': props.memberData.DataType, 'data-fieldtype': props.memberData.FieldType, 'data-value': props.Value, 'data-visible': props.memberData.Visible, 'data-foreigntypename': props.memberData.ForeignTypeName, 'data-required': props.memberData.Required, 'data-listtype': props.memberData.ListType, 'data-navigationfield': props.memberData?.NavigationField }
}

export function defaultGridProps(RecordID?: number) {
    const ret = new GridProps()
    ret.Page = 1
    ret.SettingGroup = SettingGroup.Columns
    if (RecordID || RecordID === 0) {//0 is important for commands that work on lists (like delete) should happen on all recs when invoked on new rec
        ret.RecordKeys = new Set([RecordID.toString()])
        //ret.RecordValues = { ID: RecordID }
    }
    return ret
}

export function range(first: number, last: number): number[] {
    return [...new Array(last + 1 - first)].map((_, i) => i + first);
}

export function weekFirstDay(date: Date) {
    return new Date(new Date(date).setDate(date.getDate() - date.getDay()))
}
export function monthFirstDay(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1)
}
export function getClientUrl(reportID?: number, recordType?: string, recordID?: number, action?: string, activeTab?: number, foreignController?: string, foreignRecordID?: number) {
    let urlstart = ''
    let searchParams = '?'
    function addToSearch(toAdd: string) {
        if (searchParams !== '?') searchParams += '&'
        searchParams += toAdd
    }

    if (reportID) {
        urlstart += '/Report/' + reportID
    } else {
        if (recordType) urlstart += '/' + recordType;
        if (action) urlstart += '/' + action;
        if (recordID != undefined) {
            urlstart += '/Edit/' + (recordID || 0);
            if (activeTab) urlstart += '/' + activeTab;
        }
        if (foreignController && foreignRecordID) {
            addToSearch("ForeignRecordType=" + foreignController + "&ForeignRecordID=" + foreignRecordID)
        }
    }
    if (searchParams !== '?') urlstart += searchParams
    return urlstart
}
export function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}
export function redirect(reportID?: number, controller?: string, recordID?: number, sametab = true) {
    let url = getClientUrl(reportID, controller, recordID)
    if (url === "") url = "/"
    if (sametab) window.location.hash = url
    else {
        if (window.location.hash === "") window.location.hash = "/" //if the url ends with "/#" and no "/" 
        window.open(window.location.href.replace(window.location.hash, '#') + url)
    }
}

export function inputStyle(input: CSSProperties | undefined, context: MainAppType, focused = false) {
    //const stl = input || {}
    const stl: CSSProperties = { ...input }
    if (!stl.backgroundColor && context.data.PrimaryColor) stl.backgroundColor = adjustColor(context.data.PrimaryColor, .95)
    if (!stl.borderRadius) stl.borderRadius = "1ch"
    if (stl.padding === undefined) stl.padding = ".7ch"
    stl.fontWeight = 500
    //stl.transition = 'outline-width 1s, background-color .8s'

    if (focused) {
        stl.outlineColor = numberColor(context.data.SecondaryColor)
        stl.outlineStyle = "solid"
        stl.outlineWidth = 2
    }

    return stl
}

export function UtcDate(input: Date) {
    return new Date(Date.UTC(input.getFullYear(), input.getMonth(), input.getDate(), input.getHours(), input.getUTCMinutes(), input.getSeconds()))
}

export function parseDate(input: string, format = 'yyyy-mm-ddThh:nn:ss', context?: MainAppType) {
    if (!input) return undefined

    if (input.split(" ").length == 3) {
        return gregorianDate(context!, input)
    }

    let fmt = format
    const bks = String.fromCharCode(92)
    if (input.includes(" ") && !fmt.includes(" ")) fmt = fmt + " hh:nn:ss"

    fmt = fmt.replace(/d+/i, "(?<c>\\d+)")//after this we can freely add "d"s, and the wont getr replaced
    const ys = fmt.match(/y+/i)![0]
    const ypat = `(?<a>${(bks + "d").repeat(ys.length)})`
    fmt = fmt.replace(ys, ypat)
    fmt = fmt.replace(/m+/i, "(?<b>\\d+)")
    fmt = fmt.replace(/h+/i, "(?<d>\\d+)")
    fmt = fmt.replace(/n+/i, "(?<e>\\d+)")
    fmt = fmt.replace(/s+/i, "(?<f>\\d+)")



    const regex1 = RegExp(fmt);
    const parts = regex1.exec(input);
    if (!parts) return undefined

    const a = +parts!.groups!["a"]
    if (a < 2) return undefined//probably default .net date 0001
    const b = +parts!.groups!["b"]
    const c = +parts!.groups!["c"]
    const d = +parts!.groups!["d"] || 0
    const e = +parts!.groups!["e"] || 0
    const f = +parts!.groups!["f"] || 0
    const dt = new Date(Date.UTC(a, b - 1, c, d, e, f))
    return dt
}

export function testid(input: ControlProps2) {
    //return "Test_Field_" + input.Name
    return "Test_Field_" + input.memberData.Name
}

export function divideFloat(firstNum: number, secondNum: number) {
    const ndI = 1 + ''.indexOf.call(firstNum, '.'); //Index of the Number's Dot
    const ddI = 1 + ''.indexOf.call(secondNum, '.'); // Index of the Divisors Dot
    if (ndI || ddI) { // IF its a float
        const deci = Math.max(('' + firstNum).length - ndI, ('' + secondNum).length - ddI); //Longest Decimal Part
        const pow = Math.pow(10, deci);
        return (firstNum * pow) / (secondNum * pow);
    }
    return firstNum / secondNum;
}

export async function downloadFile(rsp: Response) {
    const disposition = rsp.headers.get('Content-Disposition');
    const a = document.createElement('a');
    const blob = await rsp.blob();
    const url = URL.createObjectURL(blob);
    a.style.display = 'none';
    a.href = url;
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition!);
    if (matches != null && matches[1]) {
        var fn = matches[1];
        if (fn.startsWith('UTF-8')) {
            fn = fn.replace("UTF-8''", "");
            fn = decodeURI(fn);
        }
        a.download = fn.replace(/['"]/g, '');
    }
    document.body.appendChild(a);
    a.click();
}

export function downloadByteString(base64Data: string, fileName: string): void {
    const byteCharacters = atob(base64Data)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'application/octet-stream' })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}


export function hasFile(obj: any) {
    //const ents = Object.entries(obj)
    const ents = recordValues(obj)
    const ret = ents.some(x => {
        const val = x.Value//  x[1]
        if (val) {
            if (val instanceof File) {
                return true
            }
            else if (typeof val == 'object') {
                const ret = hasFile(val)
                return ret
            }
        }
        return false
    })
    return ret
}

export function docSource(RecordType: string, RecordID: number, Column: string, Page?: number, FileAction?: FileActions) {
    const props = { RecordType, ID: RecordID, Column, Page, FileAction }
    const url = getGlobalUrl("GetDoc", props)
    return url
}
export function propsDocSource(props: ControlProps2, page?: number, action?: FileActions) {
    if (props.memberData.IsRecordType === false) return undefined
    if (!props.recordID) return undefined
    const ret = docSource(props.recordType!, props.recordID, props.memberData.Name!, page, action)
    return ret
}

export function flatten(obj: any) {
    let ret: [string, string | Blob][] = [];
    //Object.entries(obj).forEach(x => {
    recordValues(obj).forEach(x => {
        const key = x.Key//  x[0]
        const val = x.Value// x[1]
        const isary = Array.isArray(obj)
        const isary2 = Array.isArray(val)
        if (val !== null && val != undefined) {
            if (val instanceof File) {
                ret.push([key, val])
            }
            else if (typeof val == "object") {
                const flts = flatten(val)
                const mp = flts.map(y => {
                    const ky = (isary ? '[' + key + ']' : key) + (isary2 ? '' : '.') + y[0];
                    const vl = y[1]
                    return [ky, vl] as [string, string | Blob]
                })
                ret = ret.concat(mp);
            }
            else {
                ret.push([key, val as string | Blob])
            }
        }

    })
    return ret
}

export function rightCut(input: string, toCut: string) {
    let ret = input;
    if (ret.endsWith(toCut)) {
        ret = input.substring(0, input.length - toCut.length);
    }
    return ret;
};

export function leftCut(input: string, toCut: string) {
    let ret = input;
    if (ret.startsWith(toCut)) {
        ret = input.substring(toCut.length);
    }
    return ret;
}

export interface Stored<T> {
    expires: string,
    readonly item: T
}

export class Stored<T> implements Stored<T> {
    expires: string;
    constructor(readonly item: T) {
        const expires = new Date();
        expires.setHours(expires.getHours() + 1);
        this.expires = expires.toISOString();
    }
    store(key: string) {
        localStorage.setItem(key, JSON.stringify(this));
    }
    static tryLoad<T>(key: string): T | null {
        const serialized = localStorage.getItem(key);

        if (serialized) {
            const stored = JSON.parse(serialized) as Stored<T>;
            if (new Date() < new Date(stored.expires)) {
                return stored!.item;
            }
        }

        return null;
    }
}

export async function GetFileBytes(file: File) {
    const base64 = (await ReadBytes(file)).toString();
    return base64.substring(base64.indexOf(',') + 1, base64.length);
}

export const ReadBytes = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
        const res = reader.result as string
        resolve(res)
    }
    reader.onerror = error => reject(error)
});

export const ReadText = (file: File) => new Promise<string>(callback => {
    const reader = new FileReader()
    reader.readAsText(file);
    reader.onload = () => {
        const res = reader.result as string
        callback(res)
    };
    reader.onerror = () => { throw reader.error }
})
export function controlRecord2(fields: ControlProps2[], recordID?: number) {
    const ret: any = {}
    ret.ID = recordID
    fields = fields.filter(x => x.memberData && x.memberData.DataType !== DataTypes.Bytes && !x.memberData.SubReportID)//bytes are in seperate fields whcih dont have their datatypes set. additionfields dont have memberdata. we need to send also noneditable fields. otherwise a new fieldsetting wont send recordtype/fieldname. and they wontcome back from the server.
    fields.map(x => {
        let val = x.Value
        if (x.memberData && x.memberData.ListType) {
            if (val) {
                const recs: RecordsData = val
                const newsubrecs: any[] = []
                recs.Records?.filter(x => !x.IsDeleted).forEach(x => {
                    const newsubrec: Record<string, any> = {}
                    const subflds = getControlProps3(x, recs.Columns).filter(x => !x.memberData.IsCommand)
                    subflds.forEach(y => {
                        let v = y.Value
                        if (v === undefined) v = null
                        newsubrec[y.memberData.Name!] = v
                    })
                    newsubrec.ID = x.RecordID;
                    newsubrec.InUserInterface = true;
                    newsubrecs.push(newsubrec)
                })
                val = newsubrecs
            }
        }
        //if (val === undefined) val = ""
        if (val === undefined) {
            val = ""
        }
        ret[x.memberData.Name!] = val
    })
    return ret
}
export function controlRecord(input: ControlProps2[], recordID?: number, domainID?: number) {
    const rec = input.reduce((acc, cur) => {
        const newval = cur.Value
        //if (cur.Name == "DomainID" && newval) domainID = newval
        if (cur.memberData.Name == "DomainID" && newval) domainID = newval
        //return (acc[cur.Name!] = newval, acc)
        return (acc[cur.memberData.Name!] = newval, acc)
    }, {} as any)
    let ret = { ...rec, ID: recordID }
    if (domainID) ret = { ...ret, DomainID: domainID }
    return ret
}

export function reloadPage() {
    document.location.reload()
}

export function colorNumber(hex: string) {
    let clr = hex.replace('#', '');
    return parseInt(clr, 16);
}

export function numberColor(input: number | undefined) {
    if (!input) return ''
    return '#' + input.toString(16).padStart(6, '0')
}
export function isEqual<T extends object>(obj1: T | undefined, obj2: T | undefined) {
    if (obj1 === obj2) return true; // Handles both being undefined or strictly equal
    if (!obj1 || !obj2) return false; // Handles one being undefined while the other is not

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        const val1 = obj1[key as keyof T];
        const val2 = obj2[key as keyof T];

        if (Array.isArray(val1) && Array.isArray(val2)) {
            if (val1.length !== val2.length || !val1.every((v, i) => isEqual(v, val2[i]))) {
                return false;
            }
        } else if (val1 !== val2) {
            return false;
        }
    }

    return true;
}

export function objectDiffs<T extends object>(obj1: T | undefined, obj2: T | undefined) {
    const ret = [""]
    if (obj1 === obj2) {
        ret.push("obj1")
        return ret
    }
    if (!obj1 || !obj2) {
        ret.push("obj1")
        return ret
    }


    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        ret.push("keys")
        return ret
    }

    for (const key of keys1) {
        const val1 = obj1[key as keyof T];
        const val2 = obj2[key as keyof T];

        if (Array.isArray(val1) && Array.isArray(val2)) {
            if (val1.length !== val2.length || !val1.every((v, i) => isEqual(v, val2[i]))) {
                //return false;
                ret.push(key)
            }
        } else if (val1 !== val2) {
            //return false;
            ret.push(key)
        }
    }

    return ret
}

export function enumList(enumObject: any) {
    const keysList = Object.keys(enumObject).filter(key => key !== String(parseInt(key, 10)))
    //const ret = keysList.map(x => ({ Key: +enumObject[x], Value: app.localized(x) }))
    const ret = keysList.map(x => ({ Key: +enumObject[x], Value: x }))
    return ret
}
export function myStringify(input: any) {
    const ret = JSON.stringify(input, function (k, v) {
        if (v === "") return null
        //if (v === undefined) return ""
        if (v instanceof Set) return [...v]
        if (v instanceof Date) return v.toISOString()
        return v
    })
    return ret
}
export function showConfirm(message: string) {
    //the odd char is so we can detect confirms in selenium
    return window.confirm('\u00A0' + message)
}
export function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => {
            setMatches(media.matches);
        };
        media.addListener(listener);
        return () => media.removeListener(listener);
    }, [matches, query]);
    return matches;
}

export function useMaxWidth(pixels: number) {
    return useMediaQuery(`(max-width: ${pixels}px)`)
}

export type TabType = { titleText?: string, style?: React.CSSProperties, onClick?: () => void, titleCount?: number, onResultsFetched?: (count: number) => void, titleElement?: ReactElement, children?: ReactNode, reportID?: number, testName?: string }

export function filteredProps(input: ControlProps2[], phrase?: string) {
    if (!phrase) return input
    const switched = keyboardSwitchedText(phrase)
    //return input.filter(x => [x.memberData.LocalName, x.Name].some(y => [phrase, switched].some(term => y?.toLowerCase()?.includes(term.toLowerCase()))))
    return input.filter(x => [x.memberData.LocalName, x.memberData.Name].some(y => [phrase, switched].some(term => y?.toLowerCase()?.includes(term.toLowerCase()))))
}
export function filteredColumns<T extends ColumnData>(input: T[], phrase?: string) {
    if (!phrase) return input
    const switched = keyboardSwitchedText(phrase)
    return input.filter(x => [x.LocalName, x.Name].some(y => [phrase, switched].some(term => y?.toLowerCase()?.includes(term.toLowerCase()))))
}

export function getControlProps2(input: ControlProps[], columns: ColumnData[]) {
    const ret = input.map(x => {
        const y = x as ControlProps2
        let md = columns.find(z => z.ColumnKey === x.Key)
        if (!md) {
            var a = 1
        }
        y.memberData = md!
        return y
    })
    return ret
}

export type MainAppType = {
    data: AppData
    localized: (input: string) => string
    setUser: (userID?: number, userEmail?: string) => void
    docActive: () => boolean
    showToast?: (input: string) => void
    showPrintFilter?: (input: CommandFile2) => void
    openTable?: (gp: GridProps, onRowDoubleClick?: (record: LiteRecordData) => void) => void
    setPageTitle?: (title: string, icon?: string) => void
    openChooser?: (input: ColumnChoice, reloader?: () => void) => void
    userEmail?: string
    openRecord?: (props: RecordFormProps) => void
    setSearchTerm?: (term: string) => void
    devLog?: (message: string, ...params: any[]) => void
    showContextMenu?: (event: React.MouseEvent, invoker?: (command: ColumnData2) => void, items?: ColumnData2[], field?: ControlProps2, quickAdds?: { Key: string, Value: string }[], colSetter?: (column: ColumnData) => void, reloader?: () => void, gridProps?: GridProps, column?: ColumnData, recordID?: number) => void
    printHtml?: (body: string) => void;
}
export interface RecordFormProps {
    setSaveHandler?: any
    onSaved?: (id?: number, oldId?: number) => void
    onRefreshed?: (state: RecordsData) => void
    onDirty?: (isDirty: boolean) => void
    record?: RecordsData
    presetValues?: any
    isPopup?: boolean
    onClose?: () => void
    addingRecord?: any
    addingField?: string
    addingRecordType?: string
}

export function parsedSeconds(seconds: number, interval?: Intervals) {
    const isminus = (seconds < 0)
    seconds = Math.abs(seconds)
    if (!interval) {
        if (seconds >= 31536000) {
            interval = Intervals.Year
        } else if (seconds >= 2628000) {
            interval = Intervals.Month
        } else if (seconds >= 604800) {
            interval = Intervals.Week
        } else if (seconds >= 86400) {
            interval = Intervals.Day
        } else if (seconds >= 3600) {
            interval = Intervals.Hour
        } else if (seconds >= 60) {
            interval = Intervals.Minute
        } else if (seconds >= 1) {
            interval = Intervals.Second
        } else {
            interval = Intervals.Millisecond
        }
    }
    const factr = multipliers()[interval]
    if (isminus) seconds = -seconds
    const number = seconds / factr

    return KVPair(interval, number)
}


export function formattedNumber(context: MainAppType, value: number) {
    const dat = context.data
    const curdecimals = value.toString().split('.')[1]?.length || 0
    let disdecimals = Math.max(dat.MinDecimals, curdecimals)
    if (dat.MaxDecimals !== undefined) disdecimals = Math.min(disdecimals, dat.MaxDecimals)
    return value.toLocaleString(undefined, { minimumFractionDigits: disdecimals, maximumFractionDigits: disdecimals })
}

export function multipliers() {
    const ret = {
        [Intervals.Millisecond]: 0.001,
        [Intervals.Second]: 1,
        [Intervals.Minute]: 60,
        [Intervals.Hour]: 3600,
        [Intervals.Day]: 86400,
        [Intervals.Week]: 604800,
        [Intervals.Month]: 2628000,
        [Intervals.Year]: 31536000
    }
    return ret
}
export function isSameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}
export function getWeekNumber(d: Date): number {
    // Copy date so don't modify original
    d = new Date(d);
    // Get first day of year
    var yearStart = new Date(d.getFullYear(), 0, 1);
    const sunday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - d.getDay());
    const millis_from_one_jan = sunday.getMilliseconds() - yearStart.getMilliseconds();
    const millis_per_day = 8.64e+7;
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(millis_from_one_jan / millis_per_day) / 7;
    // Return array of year and week number
    return weekNo;
}

export function GetMonthName(ctx: MainAppType, month: number) {
    var mnth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][month]
    return ctx.localized(mnth)
}

export function GetDayName(ctx: MainAppType, day: number): string {
    return ctx.localized((['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'])[day]) || day.toString();
}

function compare<T>(x: T, y: T) {
    if (x === y) { return 0; }
    if (x < y) { return -1; }
    return 1
}
export function findScrollableParent(element: Element) {
    while (element) {
        const { overflowY } = window.getComputedStyle(element)
        if (overflowY === 'scroll' || overflowY === 'auto') return element
        element = element.parentNode as Element
    }
    return null
}
export function selectedColor(ctx: MainAppType) {
    return adjustColor(ctx.data.PrimaryColor!, .5)
}
export function adjustColor(color: number, factor: number) {

    // Ensure the factor is between 0 and 1
    factor = Math.max(0, Math.min(1, factor));

    const clr = numberColor(color)

    // Convert hex to RGB
    let r = parseInt(clr.slice(1, 3), 16);
    let g = parseInt(clr.slice(3, 5), 16);
    let b = parseInt(clr.slice(5, 7), 16);

    // Calculate the adjusted color values
    r = Math.round(r + (255 - r) * factor);
    g = Math.round(g + (255 - g) * factor);
    b = Math.round(b + (255 - b) * factor);

    // Convert back to hex
    const hex = `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;

    return hex;
}

function roshDate(hebrewYear: number) {
    hebrewYear = hebrewYear - 1
    const civilYear = hebrewYear - 3760

    const a = (12 * hebrewYear + 17) % 19
    const b = hebrewYear % 4

    const f = 32.0441 + 1.55424 * a + 0.25 * b - 0.00317779 * hebrewYear
    let f1 = Math.floor(f)
    const f2 = f - f1

    let dow = (f1 + 3 * hebrewYear + 5 * b + 5) % 7

    // Adjust f1 and dow based on conditions
    if (dow === 2 || dow === 4 || dow === 6) {
        f1 += 1;
        dow += 1;
    }
    if (dow === 1 && a > 6 && f2 > 0.6329) {
        f1 += 2;
        dow += 2;
    }
    if (dow === 0 && a > 11 && f2 > 0.8977) {
        f1 += 1;
        dow += 1;
    }

    dow = dow % 7;
    if (dow === 0) dow = 7;

    let d = 0
    if (civilYear > 1600) {
        d = 10 + Math.floor((civilYear - 1600) / 100) - Math.floor((civilYear - 1600) / 400);
    }

    f1 += d;
    let month = 3; // March

    if (f1 > 31) {
        f1 -= 31;
        month = 4; // April
    }
    return new Date(civilYear, month - 1, f1 + 163); // Adjust month for JavaScript Date (0-indexed)
}

interface jewDate {
    year: number
    month: number
    day: number
}
export function hebrewDate(secDate: Date) {
    //let rosh2: Date
    //let d: Date
    let m: number
    let yearLength: number
    let yearType: string
    let h: number

    let hebrewYear = secDate.getFullYear() + 3760 + 1
    let rosh1 = roshDate(hebrewYear)
    let rosh2 = rosh1

    if (rosh1 > secDate) {
        //rosh2 = rosh1
        hebrewYear -= 1
        rosh1 = roshDate(hebrewYear)
    } else {
        rosh2 = roshDate(hebrewYear + 1)
    }

    let d = new Date(rosh1)
    yearLength = Math.floor((rosh2.getTime() - rosh1.getTime()) / (1000 * 60 * 60 * 24));

    switch (yearLength) {
        case 353:
            yearType = '100010101010';
            break;
        case 354:
            yearType = '101010101010';
            break;
        case 355:
            yearType = '111010101010';
            break;
        case 383:
            yearType = '1000110101010';
            break;
        case 384:
            yearType = '1010110101010';
            break;
        case 385:
            yearType = '1110110101010';
            break;
        default:
            throw new Error('Invalid year length');
    }

    m = 1;
    while (true) {
        h = 29 + parseInt(yearType.charAt(m - 1), 10);
        if (new Date(d.getTime() + h * 24 * 60 * 60 * 1000) > secDate) break;
        d = new Date(d.getTime() + h * 24 * 60 * 60 * 1000);
        m += 1;
        if (m > yearType.length) m = 1;
    }

    const hebrewDay = Math.floor((secDate.getTime() - d.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    let hebrewMonth: number;
    let addOn = '1'

    if (yearType.length === 13) {
        if (m === 7) addOn = '2'
        if (m >= 7) m -= 1
    }

    hebrewMonth = parseInt(m.toString() + addOn, 10);

    const ret: jewDate = { year: hebrewYear, month: hebrewMonth, day: hebrewDay }
    return ret
}

export function numberHebrew(input: number) {
    const ones = 'אבגדהוזחט'
    const tens = 'יכלמנסעפצ'
    const hundreds = 'קרשת'
    let result = ''
    if (input >= 100) {
        const hundredIndex = Math.floor(input / 100) - 1
        result += hundreds[hundredIndex]
        input %= 100
    }
    if (input >= 10) {
        if (input === 15) {
            result += 'טו'
            input = 0
        } else if (input === 16) {
            result += 'טז'
            input = 0
        } else {
            const tenIndex = Math.floor(input / 10) - 1
            result += tens[tenIndex]
            input %= 10
        }
    }
    if (input >= 1) result += ones[input - 1]
    return result
}
function hebrewNumber(input: string) {
    const letters = 'אבגדהוזחטיכלמנסעפצקרשת'
    let total = 0
    for (const char of input) {
        let adder = letters.indexOf(char) + 1
        if (adder > 20) { adder = (adder - 18) * 100 } else if (adder > 10) adder = (adder - 9) * 10
        total += adder
    }
    return total
}

function getYearLength(hebrewYear: number) {
    const roshDateCurrent = roshDate(hebrewYear);
    const roshDateNext = roshDate(hebrewYear + 1);
    return Math.round((roshDateNext.getTime() - roshDateCurrent.getTime()) / (1000 * 60 * 60 * 24));
}

export function recordValues(input: Record<string, unknown>) {
    const ret = Object.entries(input).map(([k, v]) => KVPair(k, v))
    return ret
}
export function dictionaryValues<K extends keyof any, V>(dict: Record<K, V>) { 
    const entries = Object.entries(dict) as [K, V][];
    //const ret= entries.map(([key, value]) => ({ key, value }));
    const ret = entries.map(x => KVPair(x[0], x[1]))
    return ret
}


function gregorianDate(context: MainAppType, input: string) {
    if (!context.data.IsDebug) return undefined
    const parts = input.split(' ')
    if (!parts[2]) return undefined
    const hDay = isNumber(parts[0]) ? +parts[0] : hebrewNumber(parts[0])

    let namMonth = ""
    //for (const x of recordValues(context.translations!)) {
    //    //if (val === parts[1] || key === parts[1]) {
    //    if (x.Value === parts[1] || x.Key === parts[1]) {
    //        namMonth = x.Key
    //        break
    //    }
    //}


    const hMonth = JMonths[namMonth as keyof typeof JMonths]
    let hYear = isNumber(parts[2]) ? +parts[2] : hebrewNumber(parts[2])
    if (hYear < 5700) hYear = hYear + 5700
    if (hYear < 5000) hYear = hYear + 5000

    const roshHashanah = roshDate(hYear)
    const monthDays: { [month: number]: number } = {}
    const isLeap = [0, 3, 6, 8, 11, 14, 17].includes(hYear % 19)
    const yearLength = getYearLength(hYear);
    const cheshvanFull = yearLength === 355
    const kislevFull = [354, 355].includes(yearLength)
    monthDays[JMonths.Tishrei] = 30
    monthDays[JMonths.Cheshvan] = cheshvanFull ? 30 : 29
    monthDays[JMonths.Kislev] = kislevFull ? 30 : 29
    monthDays[JMonths.Teves] = 29
    monthDays[JMonths.Shevat] = 30
    monthDays[JMonths.Adar] = isLeap ? 30 : 29
    if (isLeap) monthDays[JMonths.AdarII] = 29
    monthDays[JMonths.Nissan] = 30
    monthDays[JMonths.Iyar] = 29
    monthDays[91] = 30
    monthDays[101] = 29
    monthDays[111] = 30
    monthDays[121] = 29

    let totalDays = 0
    let currentMonth = JMonths.Tishrei
    while (currentMonth < hMonth) {
        totalDays += monthDays[currentMonth]
        currentMonth += 10
    }

    totalDays += (hDay - 1)
    return new Date(roshHashanah.getTime() + totalDays * 24 * 60 * 60 * 1000)
}
export function getControlProps3(record: LiteRecordData, columns: ColumnData[]) {
    const ret = columns.map(x => {
        const fld = record.Fields.find(z => z.Key === x.ColumnKey)
        const f2 = fld || new ControlProps()
        const y = f2 as ControlProps2
        y.memberData = x
        y.recordID = record.RecordID
        y.recordType = record.RecordType
        return y
    })
    return ret
}