//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { CSSProperties, FC, useContext, useState } from 'react'
import { DialogPlus, Icon, SmartButton, SmartDiv, SmartInput, useEffect2, VecIcon } from './misc'
import { ColumnChoice, ColumnData, MachshevetClient, SettingGroup } from "./Declarations"
import { MainContext, openFieldSetting } from './styles'
import { filteredColumns } from './globals'

export const ColumnChooser: FC<{ RecordType?: string, Group: SettingGroup, ReportID?: number, onChange?: () => void }> = props => {
    const ctx = useContext(MainContext)
    return <Icon name='ChooseColumns' onClick={e => {
        e.stopPropagation()
        const cc = new ColumnChoice()
        cc.RecordType = props.RecordType
        cc.Group = props.Group
        cc.ReportID = props.ReportID
        ctx.openChooser!(cc, props.onChange)
    }} />
}
export const ChooserPopup: FC<{ columnChooser: ColumnChoice, onCancel: () => void, onChanged?: () => void }> = props => {
    const ctx = useContext(MainContext)
    const [search, setSearch] = useState<string>()
    const [selection, setSelection] = useState<Set<string>>(new Set<string>())
    const [state, setState] = useState(props.columnChooser)
    const [dragging, setDragging] = useState<string | null>(null)

    const tests = state.Columns.filter(x => !x.Icon)
    if (tests.length) {
        const a = 1
    }
    const availableColumns = filteredColumns(state.Columns, search).filter(x => x.Position === undefined).sortBy(x => x.LocalName)
    const chosencols = state.Columns.filter(x => x.Position !== undefined).sortBy(x => x.Position)

    useEffect2(async () => {
        if (state.Columns.length == 0) {
            const cols = await MachshevetClient.GetColumnData(props.columnChooser.Group, props.columnChooser.Key, props.columnChooser.RecordType, state.ForAll, props.columnChooser.ReportID)
            setState(prev => ({ ...prev, Columns: cols }))
        }
    }, [state.ForAll])

    function toggleselect(name: string) {
        if (selection.has(name)) {
            const newSelection = new Set(selection);
            newSelection.delete(name);
            setSelection(newSelection);
        } else {
            setSelection(new Set([...selection, name]));
        }
    }

    function itemstyle(item: ColumnData) {
        let backclr = item.IsNew ? 'fuchsia' : ''
        if (selection.has(item.Name!)) backclr = 'lightsalmon'
        const ret: CSSProperties = { display: 'flex', alignItems: 'center', padding: '.2em', backgroundColor: backclr, fontWeight: item.Editable ? "bold" : "unset" }
        return ret
    }

    return <DialogPlus {...props} onClose={props.onCancel} title={ctx.localized("ChooseColumns")} testName="ChooseColumns" footer={
        <div>
            <SmartButton disabled={selection.size !== 1} onClick={async () => {
                const nam = [...selection][0]
                await openFieldSetting(props.columnChooser.RecordType!, nam, ctx)
            }} >{ctx.localized("Settings")}</SmartButton>
            <SmartButton testName="Okay" onClick={async () => {
                await MachshevetClient.SetColumns(state)
                props.onChanged && props.onChanged()
                props.onCancel()
            }}>{ctx.localized('Okay')}</SmartButton>
            {!state.ReportMode && <label>
                <input type="checkbox" checked={state.ForAll} onChange={async e => {
                    const forall = e.target.checked
                    setState(prev => ({ ...prev, ForAll: forall }))
                }} />
                <span>{ctx.localized('ForAllUsers')}</span>
            </label>}
            <label>
                <input type="checkbox" checked={state.ReportMode} onChange={async e => {
                    setState(prev => ({ ...prev, ReportMode: e.currentTarget.checked }))
                }} />
                <span>{ctx.localized('ReportMode')}</span>
            </label>
        </div>
    }>

        <div id="TestColumnChooser" style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', flexGrow: 1 }}>
            <SmartInput type='search' id="TestColumnSearch" value={search || ''} autoFocus onChange={e => setSearch(e.currentTarget.value)} placeholder={ctx.localized('SearchColumns') + '…'} />
            <div style={{ display: 'flex', overflowY: 'auto', gap: 5 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: "bold" }}>{ctx.localized('AvailableColumns')}</span>
                    <dl id="TestAvailables" style={{ overflowY: 'auto' }} >
                        {availableColumns.map(x => {
                            return <SmartDiv key={x.Name} style={itemstyle(x)} title={x.Name} onClick={() => toggleselect(x.Name!)} onDoubleClick={() => {
                                const newcols = state.Columns.map(y => y.Name === x.Name ? { ...y, Position: chosencols.length + 1 } : y)
                                setState(prev => ({ ...prev, Columns: newcols }))
                            }}                            >
                                <Icon name={x.Icon!} />
                                <span id={"Test" + x.Name}>{x.LocalName}</span>
                                {x.Licensed ? undefined : <span>🔒</span>}
                            </SmartDiv>
                        })}
                    </dl>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SmartButton onClick={() => {
                        const newcols = state.Columns.map(y => selection.has(y.Name!) ? { ...y, Position: chosencols.length + 1 } : y)
                        setState(prev => ({ ...prev, Columns: newcols }))
                        setSelection(new Set<string>())
                    }}>{ctx.data.IsRtl ? ">" : "<"}</SmartButton>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: "bold" }}>{ctx.localized('SelectedColumns')}</span>
                    <dl id="TestSelecteds" style={{ overflowY: 'auto' }} >
                        {filteredColumns(chosencols, search).map(x => {
                            return <SmartDiv style={itemstyle(x)} title={x.Name} key={x.Name} draggable onDragStart={() => { setDragging(x.Name!) }}
                                onDragOver={e => e.preventDefault()}
                                onDrop={() => {
                                    if (dragging) {
                                        const landingPosition = x.Position!
                                        //const newcols = state.Columns?.map(x => x.Name === dragging ? { ...x, Position: landingPosition } : (x.Position || -1) < (landingPosition || -1) ? x : { ...x, Position: (x.Position || -1) + 1 })
                                        const newcols = state.Columns?.map(x => {
                                            if (x.Name === dragging) return { ...x, Position: landingPosition }
                                            if (x.Position === undefined || x.Position < landingPosition) return x
                                            //return   (x.Position || -1) < (landingPosition || -1) ? x : { ...x, Position: (x.Position || 0) + 1 }
                                            return { ...x, Position: (x.Position || 0) + 1 }
                                        })
                                        setState(prev => ({ ...prev, Columns: newcols }))
                                    }
                                }}
                                onDoubleClick={() => {
                                    const newcols = state.Columns?.map(y => y.ColumnKey === x.ColumnKey ? { ...x, Position: undefined } : y)
                                    setState(prev => ({ ...prev, Columns: newcols }))
                                }}
                                onClick={() => toggleselect(x.Name!)} >
                                <div style={{ display: "flex" }}>
                                    <Icon name={x.Icon!} />
                                    <span id={"Test" + x.Name}>{x.Position!.toString() + ' ' + x.LocalName}</span>
                                </div>
                                {x.IsFilter && <VecIcon name='Filter' width={16} color='red' />}
                                {x.Licensed ? undefined : <span>🔒</span>}
                            </SmartDiv>
                        })}
                    </dl>
                </div>
            </div>
        </div>
    </DialogPlus>
}