//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react';
import { createRoot } from 'react-dom/client';
import { MyApp } from './newReact/MyApp';

class ReactRoot extends HTMLElement {
    connectedCallback() {
        this.style.flexGrow = "1"
        this.style.display = "flex"
        this.style.overflowY = "auto"
        document.body.style.margin = "0px"
        document.body.style.flexGrow = "1"
        document.body.style.display = "flex"
        document.body.style.overflowY = "auto"        
        const root = createRoot(this)
        root.render(<MyApp />)
    }
    constructor() {
        super()
    }
}
if (!customElements.get('react-root')) customElements.define('react-root', ReactRoot)

