//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, FC, CSSProperties, useRef, StrictMode, useMemo } from 'react'
import { useEffect2, useInterval } from './misc'
import { Routes, Route, HashRouter } from 'react-router-dom'
import { AdminTemplate } from './AdminTemplate'
import { AppData, MachshevetClient } from './Declarations'
import { MainAppType, numberColor } from './globals'
import { Portal } from './portal'
//import { AlertsContext, alertStore, MainContext } from './styles'
import { alertStore, MainContext } from './styles'

export const MyApp: FC = () => {
    const [state, setState] = useState<MainAppType>({ data: new AppData(), localized: s => s, setUser: setuser, docActive: documentActive })
    //const [alertState, setAlertState] = useState<AppData>()
    const actionTime = useRef<number>(0)
    const lastPing = useRef<number>(Date.now() - 100000)

    useEffect2(async () => {
        document.addEventListener('mousemove', async () => {
            actionTime.current = Date.now()
            if (Date.now() - lastPing.current > 100 * 1000) {
                lastPing.current = Date.now()
                await MachshevetClient.KeepSessionLive()
            }
        })
    }, [])

    function setuser(userid?: number, userEmail?: string) {
        setState(prev => ({ ...prev, data: { ...prev.data, UserID: userid }, userEmail: userEmail }))
    }

    useInterval(() => getData(), 1500, true)

    async function getData() {
        const js = await MachshevetClient.GetAppData()
        const dat = state.data
        const oldjs = JSON.stringify({ ...dat, Alerts: undefined, Popups: undefined, Populars: undefined })
        const newjs = JSON.stringify({ ...js, Alerts: undefined, Popups: undefined, Populars: undefined })
        const eq = newjs === oldjs
        if (!eq) setState(prev => ({ ...prev, data: js }))


        alertStore.setAlerts(js)


        //const oldalerts = JSON.stringify({ a: alertState?.Alerts, b: alertState?.Popups, c: alertState?.Populars })
        //const newalerts = JSON.stringify({ a: js.Alerts, b: js.Popups, c: js.Populars })
        //if (newalerts !== oldalerts) setAlertState(prev => ({ ...prev!, Alerts: js.Alerts, Popups: js.Popups, Populars: js.Populars }))
    }
    function documentActive() {
        const intvl = 3 * 60 * 1000
        const hdn = document.hidden
        const ret = !hdn && (Date.now() - actionTime.current) < intvl
        return ret
    }

    const mainstl: CSSProperties = { display: 'flex', direction: state.data.IsRtl ? "rtl" : "ltr", fontSize: `clamp(11px,1vw,${state.data.MaxFontSize}px)`, color: numberColor(state.data.PrimaryColor), fontFamily: state.data.Font, flexGrow: 1, overflowY: 'auto', fill: numberColor(state.data.PrimaryColor) }
    if (state.data.IsRemote) {
        mainstl.borderColor = "red"
        mainstl.borderWidth = 5
        mainstl.borderStyle = "solid"
    }
    //const memoizedAlertState = useMemo(() => alertState, [alertState])


    return <MainContext.Provider value={state}>
        {/*<AlertsContext.Provider value={alertState!}>*/}
        <HashRouter>
            <div id="MyAppDiv" style={mainstl}>
                <Routes>
                    <Route path="/*" element={<AdminTemplate />} />
                    <Route path='Portal' element={<Portal />} />
                </Routes>
            </div>
        </HashRouter>
        {/* </AlertsContext.Provider>*/}
    </MainContext.Provider>
}