//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { Route, Routes } from "react-router-dom"
import { ChooserPopup } from "./chooseColumns"
import { ColumnChoice, CommandMessage, GridProps, LiteRecordData, MachshevetClient, RecordsData } from "./Declarations"
import { adjustColor, dePascalCase, docSource, getGlobalUrl, getClientUrl, RecordFormProps } from "./globals"
import { GlobalSearch } from "./GlobalSearch"
import { Chatbot, ContextMenu, Dashboard, DialogPlus, EditDialog, Icon, Login, MessageEditor, NavLink, PrintFilter, SidePops, SmartSelect, TopAlerts, useEffect2, useInterval } from "./misc"
import { RecordPage } from "./RecordPage"
import { SideNav } from "./sideNav"
import { CommandPopup } from "./CommandPopup"
import { ListView } from "./ListView"
import { AppContext, AppContextType, ColumnData2, CommandFile2, CommandInputProps, doCommand, MainContext, menuField } from "./styles"
import { InsertGrid } from "./InsertGrid"
import { ReportCenter } from "./ReportCenter"
export const AdminTemplate: FC = () => {
    const ctx = useContext(MainContext)
    const [records, setRecords] = useState<RecordFormProps[]>([])
    const [cmdList, setCmdList] = useState<{ props: GridProps, rowDoubleClick?: (record: LiteRecordData) => void }>()
    const [pageTitle, setPageTitle] = useState('')
    const [pageIcon, setPageIcon] = useState<string>()
    const [cmdResult, setCmdResult] = useState<CommandInputProps>()
    const [chooser, setChooser] = useState<{ choice: ColumnChoice, reloader?: () => void }>()
    const [cmdMessage, setCmdMessage] = useState<CommandMessage>()
    const [printFilter, setPrintFilter] = useState<CommandFile2>()
    const printframe = useRef<HTMLIFrameElement>(null)
    const [toast, setToast] = useState<string>()
    const [redirectUrl, setRedirectUrl] = useState<string>()
    const [oldMenuField, setOldMenuField] = useState<menuField>()//to keep nvokers, otherwise doesnt seem to work
    const [menuItems, setMenuItems] = useState<ColumnData2[]>()
    const [menuQuickAdds, setMenuQuickAdds] = useState<{ Key: string, Value: string }[]>()
    const [menuPosition, setMenuPosition] = useState<[number, number]>()
    const [menuGrid, setMenuGrid] = useState<GridProps>()
    const [menuRecordID, setMenuRecordID] = useState<number>()
    const [logOutFlag, setLogOutFlag] = useState(true)
    const [searchTerm, setSearchTerm] = useState<string>()
    const [translations, setTranslations] = useState<Record<string, Record<string, string>>>({})
    const [showAlerts, setShowAlerts] = useState(true)

    const wasLoaded = useRef(false)

    useEffect(() => {
        document.title = pageTitle
    }, [pageTitle])

    useEffect(() => {
        const globalclick = () => setMenuPosition(undefined)
        document.addEventListener('click', globalclick)
        document.addEventListener('keydown', globalKeyPressed)
        return () => {
            document.removeEventListener('click', globalclick)
            document.removeEventListener('keydown', globalKeyPressed)
        }
    })

    useEffect(() => {
        if (showAlerts) setTimeout(() => setShowAlerts(false), 6 * 1000)
    }, [showAlerts])

    useInterval(() => {
        if (ctx.data.SystemAlerts.length) setShowAlerts(true)
    }, 10 * 60 * 1000)

    function closeRec() {
        setRecords(prev => (prev.slice(0, -1)))
    }

    async function globalKeyPressed(event: KeyboardEvent) {
        const key = event.code
        if (event.ctrlKey && key == "KeyQ") {
            const perm = await navigator.permissions.query({ name: 'clipboard-read' as PermissionName })
            if (perm.state !== 'denied') {
                const clp = await navigator.clipboard.readText()
                setSearchTerm(clp)
            }
        }
    }

    const [myContext] = useState<AppContextType>({
        closeRecord: closeRec,
        openDialog: c => setCmdResult(c),
        openMessage: setCmdMessage,
    })
    ctx.printHtml = (html: string) => {
        const pri = printframe.current!.contentWindow!
        pri.document.open()
        pri.document.write(html)
        pri.document.close()
        pri.focus()
        setTimeout(() => pri.print(), 0)
    }

    ctx.localized = str => {
        if (!str) {
            var a = 1
        }
        if (!ctx.data.UseLocalText) return dePascalCase(str)
        const dctn = translations[ctx.data.Language!]
        if (!dctn) return str
        const ret = dctn[str]
        if (!dctn.hasOwnProperty(str)) { //this finds keys with empty values as well, so we don';'t try to send again to the server
            MachshevetClient.Localize(str)
        }
        return ret || str
    }
    ctx.showToast = t => {
        setToast(t)
        setTimeout(() => setToast(undefined), 5000)
    }
    ctx.showPrintFilter = f => setPrintFilter(f)
    ctx.openTable = (gp, rdc) => setCmdList({ props: gp, rowDoubleClick: rdc })
    ctx.openChooser = (c, rld) => setChooser({ choice: c, reloader: rld })
    ctx.openRecord = rfp => setRecords(prev => [...prev, rfp])
    ctx.setPageTitle = (t, i) => {
        setPageTitle(t)
        setPageIcon(i)
    }
    ctx.setSearchTerm = t => setSearchTerm(t)
    ctx.devLog = (s, p) => { if (ctx.data.IsDebug) console.log(s, p) }

    ctx.showContextMenu = (event, invoker, items, field, quickAdds, colSetter, reloader, gridProps, col, recid) => {
        event.preventDefault()
        event.stopPropagation()
        const mf: menuField = { command: invoker, colSetter: colSetter, reloader: reloader }
        setOldMenuField(mf)
        items?.forEach(x => {
            x.recordType = gridProps?.RecordType
        })
        setMenuItems(items)
        myContext.menuField = field
        myContext.menuColumn = col
        setMenuPosition([event.clientX, event.clientY])
        setMenuQuickAdds(quickAdds)
        //if (gridProps) gridProps.Member = field?.memberData.Name
        setMenuGrid(gridProps)
        setMenuRecordID(recid)
    }

    useEffect2(async () => {

        if (!translations[ctx.data.Language!]) {
            const uit = await MachshevetClient.UITexts(ctx.data.Language!)
            setTranslations(prev => ({ ...prev, [ctx.data.Language!]: uit }))
        }
    }, [ctx.data.UserID, logOutFlag, ctx.data.Language])

    function loginRedirect() {
        if (ctx.data.UserID && !ctx.data.MustChangePassword) {
            if (redirectUrl && !redirectUrl.startsWith("#/Login")) {
                window.location.hash = redirectUrl
                setRedirectUrl(undefined)
            }
        }
        else {
            let url = window.location.hash
            setRedirectUrl(url)
            url = getClientUrl(undefined, "Login")
            window.location.hash = url
        }
    }

    useEffect(() => {
        if (wasLoaded.current) loginRedirect()
        else wasLoaded.current = true
    }, [ctx.data.UserID, ctx.data.CoinSymbol, logOutFlag])

    const showcontextmenu = menuItems && menuPosition ? true : false

    return <AppContext.Provider value={myContext}>
        <SideNav />
        <div id='div7' style={{ overflowX: 'hidden', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {cmdResult && <CommandPopup {...cmdResult} onClose={() => setCmdResult(undefined)} />}
            {records.map(rec => <EditDialog key={rec.record!.RecordType} {...rec} onClose={closeRec} />)}
            {chooser && <ChooserPopup columnChooser={chooser.choice} onCancel={() => setChooser(undefined)} onChanged={chooser.reloader} />}
            {cmdMessage && <MessageEditor {...cmdMessage} onClose={() => setCmdMessage(undefined)} />}
            {printFilter && <PrintFilter {...printFilter} onClose={() => setPrintFilter(undefined)} />}
            {cmdList &&
                <DialogPlus title={ctx.localized(cmdList!.props.RecordType!)} onClose={() => setCmdList(undefined)} footer={<div />}>
                    <ListView showTools={true} gridProps={cmdList!.props} saveState={false} onRowDoubleClick={e => {
                        if (cmdList?.rowDoubleClick) {
                            cmdList.rowDoubleClick(e)
                            setCmdList(undefined)
                        }
                    }} />
                </DialogPlus>}
            {showcontextmenu && <ContextMenu onCommand={async c => {
                //if (menuGrid) menuGrid.Member = myContext.menuColumn?.Name
                if (oldMenuField?.command) {
                    oldMenuField!.command!(c)
                } else {
                    c.reloader = oldMenuField?.reloader || myContext.menuField?.reloader
                    const recid = c.recordID || myContext.menuField?.recordID || menuRecordID
                    if (recid || recid === 0) {//0 is important for commands that work on lists (like delete) should happen on all recs when invoked on new rec
                        menuGrid!.RecordKeys = new Set([recid.toString()])
                    }
                    await doCommand(c, 0, menuGrid!, myContext, ctx, oldMenuField?.colSetter)
                }
            }} items={menuItems!} position={menuPosition} quickAdds={menuQuickAdds} gridProps={menuGrid} />}

            <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", padding: "1ch", gap: 10, boxShadow: "0 0 5px rgba(0, 0, 0, .4)" }} >
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <NavLink>
                        <img src={getGlobalUrl('Logo')} style={{ maxHeight: "3ch", maxWidth: "6vw" }} />
                    </NavLink>
                    <span> {ctx.data.Name}</span>
                    {ctx.data.Domains?.length > 0 && <SmartSelect onChange={async e => await MachshevetClient.SetDomainID(+e.currentTarget.value)}>
                        <option>{ctx.localized('All')}</option>
                        {ctx.data.Domains.options()}
                    </SmartSelect>}
                </div>
                <div style={{ display: 'flex', alignItems: "center", gap: 40 }}>
                    {ctx.data.UserID ?
                        <div style={{ display: "flex", gap: 30, alignItems: "center" }}>
                            <TopAlerts />
                            <GlobalSearch term={searchTerm} />
                            <NavLink recordType='Person' recordID={ctx.data.UserID} >
                                <div style={{ display: "flex", gap: "1ch", alignItems: "center" }} id="TestAvatar" data-email={ctx.userEmail}>
                                    <img src={docSource("Person", ctx.data.UserID, "Image")} style={{ maxHeight: "3ch" }} />
                                    {ctx.data.UserName}
                                </div>
                            </NavLink>
                            <div>
                                <Icon name='Logout' onClick={async () => {
                                    await MachshevetClient.LogOut()
                                    ctx.data.UserID = undefined
                                    setLogOutFlag(!logOutFlag)
                                }} />
                            </div>
                        </div>
                        :
                        <NavLink action='Login' >{ctx.localized('Login')}</NavLink>
                    }
                </div>
            </div>
            {toast && <div id="TestToast" style={{ backgroundColor: "palegreen", borderRadius: 5, borderColor: "lime", borderStyle: "solid", textAlign: "center", margin: 5, padding: 3 }}>{toast}</div>}
            {(pageTitle || pageIcon) && <div id='div8' style={{ backgroundColor: adjustColor(ctx.data.PrimaryColor!, .5), padding: 5, color: "white", display: "flex", gap: 5, alignItems: "center" }}>
                {pageIcon && <Icon name={pageIcon} />}
                {pageTitle}
            </div>}
            <Routes>
                {ctx.data.UserID && <Route index element={<Dashboard />} />}
                <Route path='Login' element={<Login />} />
                <Route path="/ReportCenter" element={<ReportCenter />} />
                <Route path=":RecordType" element={<ListView showTools={true} fullPage={true} />} />
                <Route path=":RecordType/Edit" element={< RecordPage />} />
                <Route path="Report/:ReportID" element={<ListView showTools={true} fullPage={true} />} />
                <Route path=":RecordType/Edit/:ID" element={< RecordPage fullPage={true} />} />
                {/*<Route path=":RecordType/Edit/:ID/:Tab" element={< RecordPage fullPage={true} />} />*/}
                <Route path=":RecordType/Insert" element={<InsertGrid />} />
            </Routes>
            <iframe ref={printframe} style={{ width: 1, height: 1, position: "absolute", opacity: 0 }} />
        </div>
        {ctx.data.IsDebug && false && <Chatbot />}
        <SidePops />

        {/*ctx.data.SystemAlerts?.length &&*/}
        {ctx.data.SystemAlerts?.length > 0 &&
            <div id="SystemAlerts" style={{ position: 'fixed', bottom: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'red', color: 'white', padding: '10px 20px', borderRadius: '5px', opacity: showAlerts ? 1 : 0, pointerEvents: showAlerts ? 'auto' : 'none' }}
            >{ctx.data.SystemAlerts.map(x => <span>{x}</span>)}
            </div>
        }
    </AppContext.Provider>
}
