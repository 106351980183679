//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { CSSProperties, FC, useContext, useEffect, useRef, useState } from 'react';
import { Preview, VecIcon, NavLink, useInterval, HamburgerMenu, Avatar2, useEffect2, SmartCount, SmartDiv, CardList, ChatList, Icon, SmartInput, SmartSelect, Popout, ReportMap, LineChart, useDebuggableState, ChartBox } from './misc'
import { Control } from './Control'
import { GridProps, MachshevetClient, ReportTypes, SettingGroup, ViewTable, ColumnData, LiteRecordData, GridSums, DataTypes } from './Declarations'
import { adjustColor, headerItem, leftCut, myStringify, numberColor, parseDate, range, rightCut, UtcDate } from './globals'
import { Calendar } from './calendar'
import { DataGrid } from './DataGrid'
import { AppContext, columnControlProps2, ColumnData2, doCommand, MainContext, prioritizeCommands, TableBodyProps } from './styles'
import { useParams } from 'react-router-dom';


export const ListView: FC<TableBodyProps> = props => {
    const app = useContext(AppContext)
    const ctx = useContext(MainContext)
    const [state, setState] = useState<ViewTable>()
    const [totals, setTotals] = useState<GridSums>()
    const [suggestions, setSuggestions] = useState<string[]>()
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
    const [menuShown, setMenuShown] = useState(false)
    //const [gridProps, setGridProps] = useState<GridProps>()
    const [gridProps, setGridProps] = useDebuggableState<GridProps>()
    const [selectedRecord, setSelectedRecord] = useState<LiteRecordData>()
    const [isLoading, setIsLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [dropValue, setDropValue] = useState<string>()
    const [commands, setCommands] = useState<ColumnData[]>()
    const [oldKeys, setOldKeys] = useState<string[]>()
    const selectedIndex = useRef(props.idxSelected)
    const ref = useRef<HTMLDivElement>(null)
    const lastRefresh = useRef<number>(0)
    const aborter = React.useRef(new AbortController())
    const { RecordType, ReportID, ID } = useParams()

    useInterval(async () => {
        if (ctx.docActive()) {
            const diff = Date.now() - lastRefresh.current
            if (diff > 8000 && !isLoading) {
                await refreshTable()
            }
        }
    }, 400, true)


    useEffect(() => {
        if (props.autoRecordType !== false) setGridProps(prev => ({ ...prev!, RecordType: RecordType, Filters: [], DisplayType: ReportTypes.List }))
    }, [RecordType])
    useEffect(() => {
        setGridProps(prev => ({ ...prev!, ReportID: ReportID ? +ReportID : undefined }))
    }, [ReportID])
    useEffect2(async () => {
        if (state) setState(prev => ({ ...prev!, Records: { ...prev!.Records, Records: [] } }))
    }, [ID])

    useEffect(() => {
        if (props.gridProps) setGridProps(prev => {
            //const { Filters, Page, RecordKeys, ...restGridProps } = props.gridProps!
            const { Page, RecordKeys, ...restGridProps } = props.gridProps!
            const mergedProps = { ...prev, ...restGridProps }
            return mergedProps as GridProps
        })
    }, [props.gridProps])


    useEffect2(async () => {
        //if (gridProps?.RecordType || gridProps?.ReportID) {
        if (gridProps) {
            const cmnds = await MachshevetClient.Commands(gridProps)
            setCommands(cmnds)
        }
        //setCommands(undefined)
    }, [gridProps?.RecordType, gridProps?.ReportID, gridProps?.Member])

    useEffect2(async () => {
        await refreshTable()
    }, [gridProps])

    useEffect(() => {
        setGridProps(prev => ({ ...prev!, DisplayType: state?.ReportType || ReportTypes.List }))
    }, [state?.ReportType])
    //useEffect(() => {
    //    if (state) setGridProps(prev => ({ ...prev!, LabelReportFieldID: state.LabelReportFieldID }))
    //}, [state?.LabelReportFieldID])
    //useEffect(() => {
    //    if (state) setGridProps(prev => ({ ...prev!, ValueReportFieldID: state.ValueReportFieldID }))
    //}, [state?.ValueReportFieldID])


    useEffect2(async () => {
        let gp: GridProps | undefined
        if (props.saveState !== false) {
            const ky = stateKey()
            const stateString = window.sessionStorage[ky]
            if (stateString) {
                gp = JSON.parse(stateString) as GridProps
                if (gp.RecordKeys) gp.RecordKeys = new Set(gp.RecordKeys)
                if (gp.CalendarFromDate) gp.CalendarFromDate = parseDate('' + gp.CalendarFromDate)
                //gp.RecordType = undefined//messes up subreports
                if (!state || !gridProps?.Filters.length) setGridProps(gp)
            }
        }
    }, [gridProps?.RecordType, gridProps?.ReportID])

    const curstt = state
    const recs = state?.Records
    const rectyp = gridProps?.RecordType || recs?.RecordType

    if (!rectyp) {
        var a = 1
    }

    const repid = (ReportID ? +ReportID : undefined) || gridProps?.ReportID

    const commands2 = commands?.map(x => {
        const cd = x as ColumnData2
        cd.recordType = gridProps?.RecordType
        return cd
    })
    const actionItems = commands2 && prioritizeCommands(ctx, commands2)


    useEffect(() => {
        return () => aborter.current.abort()
    }, [])




    useEffect2(async () => {
        if (gridProps?.Term && props.showTools) {
            if (suggAborter.current) suggAborter.current.abort()
            suggAborter.current = new AbortController()
            const suggs = await MachshevetClient.GetSuggestions(gridProps, suggAborter.current.signal)
            setSuggestions(suggs)
            setShowSuggestions(true)
        } else {
            setShowSuggestions(false)
        }
    }, [gridProps?.Term])




    useEffect(() => {
        const idxSel = props.idxSelected!
        if (idxSel >= lastNum && props.resetSelected) props.resetSelected(0)
        else moveSelection(idxSel)
    }, [props.idxSelected])

    function stateKey() {
        const gp = gridProps
        //const rid = repid// gp.ReportID
        const rid = gp?.ReportID
        const mem = gp?.Member
        return gp?.RecordType + '/DataTable' + (mem ? '.' + mem : '') + (rid ? '#' + rid : '')
    }

    async function filterChanged() {
        //if (savestate) setBrowserState()
        setBrowserState()
        await refreshTable()
    }


    function getCommandValues() {
        const cmdinp = props.field?.commandInputGetter && props.field.commandInputGetter()
        return cmdinp
    }

    async function refreshTable() {
        if (!gridProps) return
        if (gridProps.ReportID === 38) {
            var a = 1
        }
        setIsLoading(true)
        aborter.current.abort()
        aborter.current = new AbortController()
        const hdr = new headerItem()
        hdr.column = recs?.Columns.find(x => x.DashboardAggregation)
        let r: ViewTable
        const dat = UtcDate(new Date())
        gridProps.CommandValues = getCommandValues()
        try {
            r = await MachshevetClient.QuickSearch(gridProps!, props.showTools, dat, aborter.current.signal)
        } catch (e) {
            if ((e as Error).name === "AbortError") { return } else throw e
        }
        if (!r) return
        if (state?.Records.Records) setOldKeys(state.Records.Records.map(x => x.RecordKey!))
        setState(r)
        if (r.Records) hdr.column = r.Records.Columns.find(x => x.DashboardAggregation)

        if (props.fullPage) ctx.setPageTitle!(r.DisplayName! + (repid ? " (" + ctx.localized("Report") + ')' : ""), r.Records?.RecordType);
        if (r.RemovedKeys.length && gridProps?.RecordKeys.size) {
            setGridProps(prev => {
                let keys = [...prev!.RecordKeys]
                keys = keys.filter(x => !r.RemovedKeys.includes(x))
                const newkeys = new Set(keys)
                return ({ ...prev!, RecordKeys: newkeys })
            })
        }
        const ttls = await MachshevetClient.GetTotals(gridProps!, aborter.current.signal)
        setTotals(ttls)
        if (hdr.column) hdr.aggregation = ttls.Sums?.find(x => x.Key === hdr.column!.Name)?.Value
        hdr.count = ttls.Count
        //}
        props.onResultsFetched && props.onResultsFetched(hdr)
        lastRefresh.current = Date.now()//means data has been filled. necessary for inactive tabs on first show
        setIsLoading(false)
        if (!loaded) setLoaded(true)
    }

    function setBrowserState() {
        if (props.saveState !== false) {
            const js = myStringify(gridProps)
            const ky = stateKey()
            window.sessionStorage[ky] = js
        }
    }

    function getResultOptions() {
        const lc = leftCut(ctx.data.ResultOptions || "", ',')
        const rc = rightCut(lc, ',')
        return rc.split(',').map(x => x.trim())
    }


    let curpage = gridProps?.Page || 1

    const skippedrows = curstt?.PageRows! * (curpage - 1)
    const rowcount = recs?.Records?.length || 0

    const lastNum = skippedrows + rowcount
    const pageCount = curstt?.PageRows ? ((Math.ceil((totals?.Count || 0) / curstt?.PageRows)) || 0) : 0

    if (totals?.Count && skippedrows > totals?.Count) {
        curpage = 1//pageCount
        setGridProps(prev => ({ ...prev!, Page: curpage! }))
    }

    const end = (Math.min(pageCount, curpage! + 5)) || 0
    let navnums: number[] = []

    function inputKeyDown(event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) {
        const key = event.key;
        const cursel = selectedIndex.current === undefined ? -1 : selectedIndex.current
        if (key === 'ArrowDown') moveSelection(cursel + 1);
        else if (key === 'ArrowUp') moveSelection(cursel - 1);
        else if (key === "Esc" || key === "Escape") {
            gridProps!.Term = ""
            setSuggestions(undefined)
        }
    };

    function moveSelection(index: number) {
        let newidx = index
        if (index < 0) {
            newidx = lastNum - 1;
            if (props.resetSelected) props.resetSelected(newidx);
        }
        if (curstt) {
            const toRow = recs!.Records[newidx]
            setKeys([toRow], true, true)
        }
        selectedIndex.current = newidx;
    }

    function setKeys(records: LiteRecordData[], select: boolean, unselectOthers?: boolean) {
        const keys = records.map(x => x.RecordKey!)
        let seld = (gridProps?.RecordKeys && [...gridProps.RecordKeys]) || []
        if (unselectOthers) seld = keys
        else if (select) seld = seld!.concat(keys)
        else seld = seld.filter(x => x !== keys[0])
        setGridProps(prev => ({ ...prev!, RecordKeys: new Set(seld) }))
        gridProps!.RecordKeys = new Set(seld)//no choice. since we dont know When the sate will have the New data, and for commands we must be uptodate
        if (props.handleSelection) handleSelection(seld)//the if seeems wrong here. ymo.
        if (props.handleSelections) props.handleSelections(new Set(seld))
        setSelectedRecord(records[records.length - 1])
    }

    function handleSelection(selected: string[]) {
        let selRowData: { id: number, name: string } = { id: 0, name: "" };
        const rData = recs?.Records?.find(x => x.RecordKey === selected[0]);
        if (rData) selRowData = { id: rData.RecordID!, name: rData.RecordName! };
        if (selRowData.id > 0) props.handleSelection && props.handleSelection(selRowData.id, selRowData.name);
    }

    if (pageCount > 1) navnums = navnums.concat([1])
    if (pageCount === 2) navnums = navnums.concat([2])
    else if (pageCount > 2) {
        if (curpage === 1) navnums = navnums.concat(makeNext())
        else if (curpage === pageCount) navnums = navnums.concat(makePrev(), [curpage])
        else if (curpage === 2) navnums = navnums.concat([curpage], makeNext())
        else navnums = navnums.concat(makePrev(), [curpage], makeNext())
    }

    function makeNext() {
        let next: number[];
        if (curpage === (pageCount - 1)) next = [pageCount]
        else {
            next = [pageCount + 1].concat(range(curpage + 2, end))
            if (end < pageCount - 1) next = next.concat([-1, pageCount])
            else if (end == pageCount - 1) next = next.concat([pageCount])
        }
        return next;
    }

    function makePrev(): number[] {
        let prev: number[]
        if (curpage == 3) prev = [0]
        else if (curpage <= 7) prev = range(2, curpage - 2).concat([0])
        else prev = [-1].concat(range(curpage - 5, curpage - 2), [0])
        return prev
    }

    const navdata = navnums.map(x => ({
        num: x == 0 ? curpage - 1 : x == pageCount + 1 ? curpage + 1 : x,
        text: x == 0 ? ctx.localized('Previous') : x == pageCount + 1 ? ctx.localized('Next') : x == -1 ? '...' : x.toString()
    }))

    const sCell: React.CSSProperties = { padding: "4px 8px" }
    if (curstt?.ValueBorderColor) {
        sCell.borderColor = numberColor(curstt?.ValueBorderColor)
        sCell.borderStyle = "solid"
        sCell.borderWidth = 1
    }

    async function docmd(c: ColumnData2) {
        c.reloader = () => refreshTable()
        //gridProps.RecordValues = props.field?.modelGetter && props.field.modelGetter()
        //await doCommand(c, totals?.Count || 0, gridProps, app!, ctx, async newcol => {
        await doCommand(c, totals?.Count || 0, gridProps!, app!, ctx, async newcol => {
            setGridProps(prev => {
                if (!prev!.Filters.some(z => z.ColumnKey === newcol.ColumnKey)) prev!.Filters.push(newcol)
                prev!.Filters = prev!.Filters.map(y => y.Name === newcol.Name ? newcol : y)
                return prev!
            })
        }, undefined)//, getCommandValues()
    }

    const suggAborter = React.useRef(new AbortController())
    const hasfilter = gridProps?.Term || recs?.Columns.some(x => x.AutoFilter || x.DateRange || x.FilterList || x.FilterText || x.IDFilter || x.MinFilter || x.MaxFilter || x.StartSpan || x.EndSpan || (x.BarFilterValues || []).size)
    const dispTypes = [ReportTypes.List, ReportTypes.Calendar, ReportTypes.Cards, ReportTypes.Chat, ReportTypes.Map, ReportTypes.LineChart]
    const partstyle: CSSProperties = { borderWidth: 1, borderStyle: "solid", borderColor: numberColor(ctx.data.SecondaryColor), borderRadius: 6, padding: 5 }
    const barfilters = recs?.Columns.filter(x => x.FilterBar)

    const inpparams = state && columnControlProps2(state.InputParams)
    const topcmnds = commands2?.filter(x => x.NeedsField !== true).sortBy(x => [-x.Uses, x.DefaultPosition || 100]).slice(0, 6)

    const props2 = { ...props, gridProps: gridProps! }
    props2.setSelected = setKeys
    props2.refreshData = () => refreshTable()
    props2.records = recs
    //props2.chartData = state?.ChartData
    props2.commands = commands
    props2.timeDiff = state?.TimeDiff
    props2.setMenuField = (e, f) => {
        ctx.showContextMenu!(e, docmd, actionItems, f, recs?.QuickAdds, undefined, undefined, gridProps)
    }
    props2.totals = totals
    props2.onPropsChanged = async e => {
        setGridProps(e)
        await filterChanged()
    }
    props2.oldKeys = oldKeys
    props2.lastRefresh = lastRefresh.current

    return <div id="TestGridContainer" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", overflowY: "hidden", flexGrow: 1, gap: ".5vh", padding: "1vh" }} ref={ref}>
        <div id={"TestGrid" + repid}>

            {props.showTools !== false && <div id={"Test_GridTools_" + rectyp}>
                {state && repid && <div style={{ display: 'flex', padding: "1vh", gap: "1vh" }}>
                    <NavLink recordType={rectyp} style={{ fontWeight: 'bold' }}>{ctx.localized(rectyp!)}</NavLink>
                    <div style={{ display: "flex", gap: 10 }}>
                        {state?.Filters?.map(x => {
                            if (x.Group == 0) return x.Descriptions.map(y => <span key={y.Key}>{y.Key}:&nbsp;<b>{y.Value}</b></span>)
                            else {
                                const orFilters = x.Descriptions.map<React.ReactNode>(y => <span key={y.Key}>{y.Key}:&nbsp;<b>{y.Value}</b></span>)
                                if (orFilters.length > 0)
                                    return orFilters.map<React.ReactNode>(t => <span>{t}</span>).reduce((prev, current) => [prev, (<b style={{ color: "lightblue" }}> {ctx.localized("Or")} </b>), current])
                                else
                                    return null
                            }
                        })}
                    </div>
                </div>}
                <div style={{ display: "flex", gap: "0.5vmax", alignItems: 'center' }}>
                    <div style={{ display: 'flex', gap: ".2vw" }}>
                        {topcmnds?.map(x => <Avatar2 key={x.Name} {...x} gridProps={{ ...gridProps!, Member: x.Name }} showTools={true} totalRows={totals?.Count} reloader={() => refreshTable()} />)}
                    </div>
                    <div style={{ flexGrow: 1 }} onMouseLeave={() => setShowSuggestions(false)}>
                        <SmartInput id="TestSearch" type="search" value={gridProps?.Term || ""} placeholder={ctx.localized("Search")} onChange={async e => {
                            const trm = e.currentTarget.value
                            setGridProps(prev => ({ ...prev!, Term: trm }))
                        }} onKeyDown={inputKeyDown} onMouseEnter={() => setShowSuggestions(true)} />
                        {showSuggestions && suggestions && <Popout style={{ borderColor: "lightgray", borderWidth: 2, borderStyle: "solid", padding: 3, borderRadius: "0px 0px 5px 5px", zIndex: 2 }} >{suggestions?.map(x => <SmartDiv key={x} onClick={() => {
                            setGridProps(prev => ({ ...prev!, Term: x }))
                            setSuggestions(undefined)
                        }}>{x}</SmartDiv>)}
                        </Popout>}
                    </div>
                    <Icon name="ClearGridFilters" color={hasfilter ? "orange" : undefined} fontSize="130%" onClick={async () => {
                        if (gridProps?.Filters) setGridProps(prev => ({ ...prev!, Filters: prev!.Filters.map(x => ({ ...x, IDFilter: "", FilterText: "", MinFilter: "", MaxFilter: "", AutoFilter: undefined, FilterList: "", ReverseFilter: undefined, DateRange: undefined, StartSpan: undefined, EndSpan: undefined, BarFilterValues: new Set<string>() })), Term: "", RecordKeys: new Set<string>(), Page: 1, InputParams: [] }))
                        setSuggestions(undefined)
                    }} />
                    <div id="TestInputParams" style={{ display: "flex", gap: 10 }}>
                        {inpparams && inpparams.map(x => <div key={x.ColumnKey} id={"Test_Control_" + x.Name}><div style={{ whiteSpace: "nowrap" }} >{x.memberData!.LocalName}</div>
                            <Control term={gridProps?.Term} field={{
                                ...x, recordType: x.ForeignTypeName, showTools: false, editPage: true, Value: gridProps?.InputParams?.find(y => y.Key === x.Name)?.Value,
                                onChange: async e => {
                                    if (gridProps) {
                                        const ip = gridProps.InputParams.find(y => y.Key === x.memberData!.Name)
                                        if (ip) ip.Value = e
                                        else gridProps.InputParams.push({ Key: x.memberData!.Name!, Value: e })
                                        setGridProps(gridProps)
                                    }
                                    await filterChanged()
                                }
                            }} /></div>
                        )}
                    </div>
                    <SmartSelect onChange={async e => {
                        const rid = +e.currentTarget.value
                        gridProps!.FilterReportID = rid
                        setGridProps(gridProps!)
                        await refreshTable()
                    }}>
                        <option key="">{ctx.localized("ReportFilter") + "..."}</option>{state && state.FilterReports && state.FilterReports.map(x => <option key={x.Key} value={x.Key}>{x.Value}</option>)}
                    </SmartSelect>
                    {state && <>
                        <Icon color={numberColor(ctx.data.PrimaryColor)} name={state.ShowPreview ? "HidePreview" : "ShowPreview"} onClick={async () => {
                            const newvalprevw = !state.ShowPreview
                            setState(prev => ({ ...prev!, ShowPreview: newvalprevw }))
                            await MachshevetClient.SetSetting(gridProps?.SettingKey!, newvalprevw.toString(), SettingGroup.ShowPreview, rectyp, repid)
                        }} />
                        <Icon color={numberColor(ctx.data.PrimaryColor)} name={menuShown ? "HideMenu" : "ShowMenu"} onClick={async () => {
                            setMenuShown(!menuShown)
                        }} />
                    </>}
                </div>
            </div>}
        </div>
        {state && <div id={"TestGrid_" + gridProps?.RecordType} style={{ display: "flex", overflowY: "hidden", flexGrow: 1, gap: "1vh" }}>
            {barfilters && barfilters.length > 0 && props.showTools &&
                <div id="TestBarFilters" style={{ ...partstyle, overflowY: "auto", fontSize: "90%" }}>
                    {barfilters.map(x => {
                        const bfs = gridProps?.Filters
                        const bf = bfs?.find(z => z.ColumnKey === x.ColumnKey)
                        const vals = [...bf?.BarFilterValues || new Set<string>()]
                        const bfopts = totals?.BarFilters.find(y => y.Key === x.ColumnKey)?.Value
                        return <div key={x.ColumnKey} id={"TestFilterBar_" + x.Name}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ fontWeight: "bold" }}>{x.LocalName}</span>
                                <div style={{ display: 'flex' }}>
                                    <SmartDiv onClick={async () => {
                                        const curval = vals[0]
                                        const curindex = bfopts!.findIndex(y => y.Key === curval)
                                        let newval = bfopts![curindex + 1]
                                        if (!newval) newval = bfopts![0]
                                        //setGridProps(prev => ({ ...prev!, Filters: prev!.Filters.map(y => y.SelectName === x.SelectName ? { ...y, BarFilterValues: new Set([newval.Key!]) } : y) }))
                                        setGridProps(prev => ({ ...prev!, Filters: prev!.Filters.map(y => y.ColumnKey === x.ColumnKey ? { ...y, BarFilterValues: new Set([newval.Key!]) } : y) }))
                                    }}>▽</SmartDiv>
                                    <SmartDiv onClick={async () => {
                                        const curval = vals[0]
                                        const curindex = bfopts!.findIndex(y => y.Key === curval)
                                        let newval = bfopts![curindex - 1]
                                        if (!newval) newval = bfopts![bfopts!.length - 1]
                                        //setGridProps(prev => ({ ...prev!, Filters: prev!.Filters.map(y => y.SelectName === x.SelectName ? { ...y, BarFilterValues: new Set([newval.Key!]) } : y) }))
                                        setGridProps(prev => ({ ...prev!, Filters: prev!.Filters.map(y => y.ColumnKey === x.ColumnKey ? { ...y, BarFilterValues: new Set([newval.Key!]) } : y) }))
                                    }}>△</SmartDiv>
                                </div>
                            </div>
                            <div>
                                {bfopts?.map(y => {
                                    const key2 = y.Key || ""
                                    const ischk = vals.includes(key2) ? true : false
                                    const isdropping = y.Key === dropValue && y.Key !== undefined
                                    return <SmartDiv key={key2} onDragOver={e => e.preventDefault()} onDragEnter={() => setDropValue(y.Key)} onDrop={async () => {
                                        await MachshevetClient.SetValue(rectyp!, gridProps!.RecordKeys, x.Name!, y.Key)
                                        await refreshTable()
                                        setDropValue(undefined)
                                    }}>
                                        <label style={{ display: 'flex', gap: 4, alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div>
                                                <input id="TestBarFilterOption" type="checkbox" onChange={async e => {
                                                    const toadd = e.target.checked
                                                    const newgp = gridProps!
                                                    if (!newgp.Filters) newgp.Filters = []

                                                    const colkey = x.ColumnKey
                                                    if (!newgp.Filters.some(filter => filter.ColumnKey === colkey)) {
                                                        // const cd: ColumnData=x
                                                        newgp.Filters.push(x)
                                                    }

                                                    newgp.Filters = newgp.Filters.map(y => y.ColumnKey === colkey ? { ...y, BarFilterValues: new Set(toadd ? [...y.BarFilterValues || []].concat(key2) : [...y.BarFilterValues].filter(x => x.toString() !== String(key2))) } : y)
                                                    setGridProps(prev => ({ ...prev!, Filters: newgp.Filters }))
                                                }} checked={ischk} />
                                                <span style={{ fontWeight: isdropping ? 900 : "unset", fontSize: isdropping ? "110%" : "unset" }}> {y.Value || "(" + ctx.localized("Empty") + ")"}</span>
                                            </div>
                                            <span style={{ fontSize: "70%" }}> <SmartCount Number={y.Count} /></span>
                                        </label>
                                    </SmartDiv>
                                })}
                            </div>
                        </div>
                    })}
                </div>}

            {(() => {
                switch (gridProps?.DisplayType) {
                    case ReportTypes.Calendar: return <Calendar {...props2} doCommand={docmd} />
                    case ReportTypes.Cards: return <CardList {...props2} doCommand={docmd} />
                    case ReportTypes.Chat: return <ChatList  {...props2} />
                    case ReportTypes.Map: return <ReportMap  {...props2} />
                    case ReportTypes.LineChart: return <ChartBox  {...props2} />
                    case ReportTypes.PieChart: return <ChartBox  {...props2} />
                    default:
                        return <DataGrid {...props2} />
                }
            })()}


            {props.showTools && state?.ShowPreview && selectedRecord && <div style={{ ...partstyle, overflow: "auto", width: "25%", display: 'flex', fontSize: "90%", resize: "horizontal" }}>{<Preview recordType={selectedRecord.RecordType!} id={selectedRecord.RecordID!} miniMode={false} />}</div>}
            {menuShown && commands2 && <div style={{ ...partstyle, display: 'flex' }}>
                <HamburgerMenu onCommand={async c => {
                    await docmd(c)
                    setMenuShown(false)
                }} items={commands2} gridProps={gridProps} /></div>}
        </div>
        }

        <div style={{ display: "flex", justifyContent: "space-between", fontSize: "90%", alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: 3 }}>
                <div>{ctx.localized('Showing') + ' ' + (skippedrows + 1) + ' ' + ctx.localized('To') + ' ' + lastNum + ' ' + ctx.localized('Of') + ' '}</div>
                <SmartCount testname="TotalRows" Number={totals?.Count || 0} />
            </div>
            {props.showTools && state && <>
                <div style={{ display: 'flex', gap: 2 }}>
                    {ctx.localized('Show') + ' '}
                    <SmartSelect id="TestMaxRowsSelector" value={state.PageRows} style={{ padding: 1 }}
                        onChange={async e => {
                            setGridProps(prev => ({ ...prev!, Page: 1 }))
                            await MachshevetClient.SetSetting(gridProps?.SettingKey!, e.currentTarget.value, SettingGroup.PageRows, rectyp, props.gridProps?.ReportID)
                            await refreshTable();
                        }}
                    >{getResultOptions()!.map(x => <option id={"TestMaxRowsSelector" + "_" + x} value={x} key={x}>{x}</option>)}
                    </SmartSelect>
                    {' ' + ctx.localized('Records')}
                </div>
                <div>
                    {ctx.localized('PivotHeaderField') + ' '}
                    <SmartSelect value={gridProps?.LabelReportFieldID} onChange={async e => {
                        setGridProps(prev => ({ ...prev!, LabelField: e.currentTarget.value }))
                    }}
                    >{[""].concat(recs!.Columns.filter(x => x.DataType === DataTypes.Text).map(x => x.Name!)).map(x => <option value={x} key={x}>{x}</option>)}
                    </SmartSelect>
                </div>
            </>}

            <Icon name="Refresh" onClick={() => refreshTable()} />
            <span id="TestLoading" >{isLoading ? "⇄" : ""} </span>

            {props.showTools && <>
                <div style={{ display: 'flex', gap: 2 }}>{dispTypes.map(x => <span key={x}> <VecIcon width={16} name={ReportTypes[x]} onClick={() => { setGridProps(prev => ({ ...prev!, DisplayType: x })) }} /></span>)}</div>
                {state && <NavLink recordType={rectyp} action="Insert" >{ctx.localized("BulkInsert")}</NavLink>}
            </>}
            <div style={{ display: 'flex' }}>
                {navdata.map(x => <SmartDiv key={x.num} style={{ padding: ".5vh", borderWidth: 1, borderStyle: 'solid', borderColor: numberColor(ctx.data.SecondaryColor), backgroundColor: x.num === curpage ? numberColor(ctx.data.PrimaryColor) : adjustColor(ctx.data.SecondaryColor!, .6) }}
                    onClick={async () => {
                        setGridProps(prev => ({ ...prev!, Page: x.num }))
                        setBrowserState()
                    }}>{x.text}</SmartDiv>)
                }
            </div>
        </div>
        {props.showTools && < div style={{ position: 'absolute', opacity: .1, bottom: 0, insetInlineEnd: 0, pointerEvents: 'none' }}> <Icon name={rectyp!} fontSize="1100%" /></div>}
    </div>
}

