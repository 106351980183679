import { invokeFunction } from "./globals";
export enum Aggregations {
    Count,
    Sum,
    Average,
    Min,
    Max,
}

export enum AlertLevels {
    Error,
    Warning,
    NoDelete,
    NoChange,
}

export class AppData{
  UseLocalText = false
  Language?: string
  IsRtl = false
  IsRemote = false
  MinDecimals = 0
  MaxDecimals?: number
  CommandsInSameTab = false
  DateEditFormat?: string
  DisplayQuickFilter = false
  NearSidePopups = false
  UserID?: number
  ResultOptions?: string
  IsSysAdmin = false
  IsSandbox = false
  UserName?: string
  MustChangePassword = false
  PrimaryColor?: number
  SecondaryColor?: number
  Name?: string
  IsDebug = false
  CoinSymbol?: string
  EnablePasswordLogin = false
  AppName?: string
  GridHeaderWrapping = false
  Font?: string
  AutoWidth = false
  MaxFontSize = 0
  ReturnOnSave = false
  Recents: DashboardTile[] = []
  Progresses: MiniExecution[] = []
  Lists: DashboardTile[] = []
  Populars: ReportLite[] = []
  QuickAdds: {Key: string, Value: string}[] = []
  SystemAlerts: string[] = []
  HasMailbox = false
  Domains: {Key: number, Value: string}[] = []
  Alerts: ReportLite[] = []
  Popups: RecordsData[] = []
}

export class Attachment{
  RecordID?: number
  Bytes?: string
  Name?: string
  Type?: string
  Action?: FileActions
  FilterColumns: ColumnData[] = []
  FilterControls: ControlProps[] = []
}

export enum AutoFilters {
    Empties,
    Fulls,
    Mine,
    Yes,
    No,
    NotZero,
    ThisWeekday,
    NotRelated,
    Duplicates,
    Positive,
    Negative,
}

export interface ChartPoint{
  Key: any
  Label?: string
  Value: any
}

export class ClipboardData{
  Text?: string
  Html?: string
}

export class ColumnChoice{
  Group: SettingGroup = 0
  RecordType?: string
  ReportID?: number
  Key?: string
  Columns: ColumnData[] = []
  ForAll = false
  ReportMode?: boolean
}

export class ColumnData{
  IsFilter?: boolean
  CanExecute?: boolean
  PickList: PickListItem[] = []
  BarFilterValues: Set<string> = new Set([])
  ColumnKey?: string
  AutoFilterList?: { [index:number]: string }
  IDFilter?: string
  FilterList?: string
  FilterText?: string
  MinFilter?: string
  MaxFilter?: string
  IncludeEmpties?: boolean
  ReverseFilter?: boolean
  DateRange?: DateRanges
  AutoFilter?: AutoFilters
  StartSpan?: number
  EndSpan?: number
  ReportFieldID?: number
  AdjustInterval?: Intervals
  DashboardAggregation?: Aggregations
  Icon?: string
  SubReportID?: number
  LocalName?: string
  Position?: number
  Name?: string
  DataType?: DataTypes
  FieldType?: FieldTypes
  IDFieldName?: string
  ForeignTypeName?: string
  Warning?: string
  Group?: string
  FontSize?: number
  FontSizePercent?: number
  FontWeight?: number
  Uses = 0
  IsNew = false
  IsRecordType?: boolean
  ShowTime?: boolean
  Visible?: boolean
  SpecificCommand?: boolean
  Required?: boolean
  IsCommand?: boolean
  Multiline?: boolean
  Mapped?: boolean
  MaxLength?: number
  KeepOpen = false
  ShowAll = false
  AutoComplete?: boolean
  WordWrap?: boolean
  FilterBar?: boolean
  NeedsField?: boolean
  NeedsReport?: boolean
  NeedsList?: boolean
  NeedsRecord?: boolean
  Locked?: boolean
  Licensed = true
  MergePicker?: boolean
  ForeColor?: number
  BackColor?: number
  DefaultPosition?: number
  Tooltip?: string
  Key?: string
  ListType?: string
  NavigationField?: string
  MeasureUnit?: Units
  DateInterval?: Intervals
  DatePrecision?: Intervals
  SortOrder?: number
  SortDescending?: boolean
  ForHeader?: boolean
  HasPickList?: boolean
  PrefillOptions?: boolean
  EditMode?: boolean
  FormatPicker?: boolean
  FieldPicker?: boolean
  HasIcons?: boolean
  IsDefault?: boolean
  Transformation?: Transformations
  Definition?: string
  FileTypes: string[] = []
  Editable = false
}

export class CommandFile{
  Bytes?: string
  Name?: string
  Type?: string
  Action?: FileActions
  FilterColumns: ColumnData[] = []
  FilterControls: ControlProps[] = []
}

export interface CommandList{
  RecordType?: string
  Filters: ColumnData[]
}

export interface CommandMessage{
  Subject?: string
  Body?: string
  Recipients: {Key: string, Value: string}[]
  FaxNumber?: number
  Attachments: Attachment[]
  ScreenName?: string
  ReplyAddress?: string
  SendSplit: boolean
}

export class ControlProps{
  Value: any
  RefID?: number
  RefType?: string
  Text?: string
  ErrorText?: string
  ErrorRecordID?: number
  Useful?: boolean
  FileType?: string
  FileText?: string
  FileHtml?: string
  Pages?: number
  Coin?: string
  WarningText?: string
  BackColor?: number
  Key?: string
}

export interface DashboardData{
  Entities: DashboardTile[]
}

export interface DashboardTile{
  RecordType?: string
  ReportID?: number
  RecordID?: number
  DisplayName?: string
  Count?: number
  AggregationFieldType?: FieldTypes
  ReportType?: ReportTypes
  Licensed?: boolean
  ForeColor?: number
  BackColor?: number
  Key?: string
}

export enum DataTypes {
    Text,
    Number,
    Boolean,
    DateTime,
    Enum,
    Bytes,
    TimeSpan,
    List,
}

export enum DateRanges {
    Today,
    ThisWeek,
    ThisMonth,
    ThisYear,
    Past,
    Future,
    PastWeek,
    PastMonth,
    PastYear,
    LastMonth,
    Yesterday,
    LastYear,
    NextMonth,
    ThisSchoolYear,
    PastHour,
    Tomorrow,
    UpcomingWeek,
}

export class EntityData{
  RecordType?: string
  Rows = 0
  Title?: string
}

export interface FieldCountData{
  Key?: string
  Value?: string
  Count: number
}

export enum FieldTypes {
    Bitmap,
    Clock,
    Color,
    Css,
    Csv,
    DrawPath,
    Email,
    Gender,
    Html,
    IsraelNationalCode,
    Measure,
    Money,
    Percent,
    Phone,
    Span,
    Weekdays,
    Track,
    Json,
    Url,
    Rtf,
    Xaml,
    FolderPath,
    Language,
    Xml,
    Audio,
    CardExpiry,
    Font,
    Pdf,
    DateFormat,
    Docx,
    Svg,
    TimeFormat,
    CardNumber,
    SocialSecurity,
}

export enum FileActions {
    Open,
    Print,
    Download,
}

export interface FilterDescription{
  Group: number
  Descriptions: {Key: string, Value: string}[]
}

export class GridProps{
  Term?: string
  ReportID?: number
  SettingGroup: SettingGroup = 2
  Member?: string
  InputParams: {Key: string, Value: any}[] = []
  RecordKeys: Set<string> = new Set([])
  Page = 1
  SettingKey?: string
  FilterReportID?: number
  CalendarWeekView = false
  CalendarFromDate?: Date
  DisplayType: ReportTypes = 0
  Command?: string
  Filters: ColumnData[] = []
  RecordType?: string
  ParentRecordType?: string
  ParentMember?: string
  LabelReportFieldID?: number
  ValueReportFieldID?: number
  LabelField?: string
  LabelInterval?: Intervals
  ValueField?: string
  RecordValues?: { [index:string]: any }
  CommandValues?: { [index:string]: any }
}

export class GridSums{
  Count = 0
  Sums: {Key: string, Value: any}[] = []
  BarFilters: {Key: string, Value: FieldCountData[]}[] = []
}

export class InputData{
  ExecutionID?: number
  Progress?: number
  Columns: ColumnData[] = []
  Fields: ControlProps[] = []
  Errors: string[] = []
}

export enum Intervals {
    Millisecond,
    Second,
    Minute,
    Hour,
    Day,
    Week,
    Month,
    Year,
}

export enum JMonths {
    Tishrei=11,
    Cheshvan=21,
    Kislev=31,
    Teves=41,
    Shevat=51,
    Adar=61,
    AdarII=62,
    Nissan=71,
    Iyar=81,
    Sivan=91,
    Tamuz=101,
    Av=111,
    Elul=121,
}

export class LiteRecordData{
  RecordID = 0
  RecordKey?: string
  RecordType?: string
  RecordName?: string
  BackColor?: {Key: string, Value: number}
  ForeColor?: number
  PersonID?: number
  AddedOn?: Date
  FromDate?: Date
  Incoming = false
  Latitude?: number
  Longitude?: number
  IsDeleted = false
  Index = 0
  TempBulkField?: string
  TempBulkIDs?: string
  TempParentRecordType?: string
  ChildKeyField?: string
  Fields: ControlProps[] = []
}

export interface LoginResult{
  PersonID?: number
  Error?: string
  Validation?: string
  MustReset: boolean
}

export interface MiniExecution{
  ID: number
  LocalCommand?: string
  Progress: number
}

export class PickListItem{
  Key: any
  Value?: string
  Icon?: string
  ForeColor?: number
}

export interface PortalData{
  UserID?: number
  Reports: ReportLite[]
  CompanyName?: string
  UserName?: string
}

export class RecordsData{
  ReportID?: number
  RecordType?: string
  TempCommand?: string
  Records: LiteRecordData[] = []
  Columns: ColumnData[] = []
  QuickAdds: {Key: string, Value: string}[] = []
  Reports: ReportLite[] = []
  SampleRecord: ControlProps[] = []
  ChartData: ChartPoint[] = []
}

export class ReportLite{
  Count = 0
  BackColor?: number
  LocalName?: string
  AggregationFieldType?: FieldTypes
  ID = 0
  RecordType?: string
}

export enum ReportTypes {
    List,
    Single,
    Count,
    Calendar,
    Cards,
    Chat,
    Popup,
    Map,
    LineChart,
    Pivot,
    PieChart,
}

export class SearchResult{
  Name?: string
  RecordID?: number
  RecordType?: string
}

export interface SearchResultGroup{
  Name?: string
  LocalName?: string
  List: SearchResult[]
  Total: number
}

export enum SettingGroup {
    AppListSort,
    EditFields,
    Columns,
    ColumnsWidth,
    Popup,
    AppEditPage,
    ColumnsSetAll,
    Options,
    PageRows,
    PanelView,
    RecordPage,
    SetValue,
    ShowAllLists,
    ShowPreview,
    Sorting,
    Splitter,
    TabSelection,
    WindowRect,
    RecordPrint,
    PrintColumns,
    CsvColumns,
    ShowFilters,
    PreviewFields,
    AppDetails,
    Misc,
    EditColumns,
    PickColumns,
    Dashboard,
}

export enum Transformations {
    HasValue,
    Length,
    Time,
    MonthsPassed,
}

export enum Units {
    Bytes,
    Centimeters,
    Celsius,
    Farenheit,
    Feet,
    FluidOunces,
    Gallons,
    Gigabytes,
    Grams,
    Inches,
    Kilobytes,
    Kilograms,
    Kilometers,
    Liters,
    Megabytes,
    Meters,
    Miles,
    Miligrams,
    Milimeters,
    Mililiters,
    Ounces,
    Pixels,
    Points,
    Pounds,
    Terabytes,
    SquareCentimeters,
    SquareMeters,
    Tons,
}

export class ViewTable{
  Records: RecordsData = new RecordsData()
  ShowPreview = false
  DisplayName?: string
  PageRows = 0
  ValueBorderColor?: number
  InputParams: ColumnData[] = []
  Filters: FilterDescription[] = []
  RemovedKeys: string[] = []
  ReportType: ReportTypes = 0
  FilterReports: {Key: number, Value: string}[] = []
  TimeDiff = 0
}
export const MachshevetClient = {ChangePassword: (NewPassword: string, signal?: AbortSignal) => invokeFunction<any>("ChangePassword", {NewPassword},false, signal),

SetDomainID: (DomainID?: number, signal?: AbortSignal) => invokeFunction<any>("SetDomainID", {DomainID},false, signal),

MicrosoftLogin: ( signal?: AbortSignal) => invokeFunction<string>("MicrosoftLogin", {},false, signal),

GenerateTempPassword: (LoginUserName: string, signal?: AbortSignal) => invokeFunction<any>("GenerateTempPassword", {LoginUserName},false, signal),

SaveBulk: (RecordType: string,Record: any, signal?: AbortSignal) => invokeFunction<any>("SaveBulk", {RecordType,Record},true, signal),

Insert: (RecordType: string,Values: { [index:string]: any }[], signal?: AbortSignal) => invokeFunction<number[]>("Insert", {RecordType,Values},true, signal),

InsertGrid: (RecordType: string, signal?: AbortSignal) => invokeFunction<RecordsData>("InsertGrid", {RecordType},false, signal),

DoCommand: (Name: string, signal?: AbortSignal) => invokeFunction<any>("DoCommand", {Name},false, signal),

SavePortalRecord: (ReportID: number,JsonInput?: { [index:string]: any },RecordID?: number, signal?: AbortSignal) => invokeFunction<any>("SavePortalRecord", {ReportID,JsonInput,RecordID},true, signal),

GetPortalRecord: (ReportID: number,RecordID?: number, signal?: AbortSignal) => invokeFunction<RecordsData>("GetPortalRecord", {ReportID,RecordID},false, signal),

GetRecordName: (RecordType: string,ID: number, signal?: AbortSignal) => invokeFunction<string>("GetRecordName", {RecordType,ID},false, signal),

PortalList: (ReportID: number, signal?: AbortSignal) => invokeFunction<RecordsData>("PortalList", {ReportID},false, signal),

GetPortalData: ( signal?: AbortSignal) => invokeFunction<PortalData>("GetPortalData", {},false, signal),

GetFieldSetting: (RecordType: string,FieldName: string, signal?: AbortSignal) => invokeFunction<RecordsData>("GetFieldSetting", {RecordType,FieldName},false, signal),

KeepSessionLive: ( signal?: AbortSignal) => invokeFunction<any>("KeepSessionLive", {},false, signal),

MoveMember: (RecordType: string,Group: SettingGroup,Name: string,PushedName: string,Key?: string,ReportID?: number, signal?: AbortSignal) => invokeFunction<any>("MoveMember", {RecordType,Group,Name,PushedName,Key,ReportID},false, signal),

MoveReport: (ID: number,PushedID: number, signal?: AbortSignal) => invokeFunction<any>("MoveReport", {ID,PushedID},false, signal),

SetFieldSetting: (RecordType: string,FieldName: string,Member: string,Value: any, signal?: AbortSignal) => invokeFunction<any>("SetFieldSetting", {RecordType,FieldName,Member,Value},true, signal),

SetValue: (RecordType: string,RecordKeys: Set<string>,FieldName: string,NewValue: any, signal?: AbortSignal) => invokeFunction<any>("SetValue", {RecordType,RecordKeys,FieldName,NewValue},true, signal),

Dial: (Number: number, signal?: AbortSignal) => invokeFunction<any>("Dial", {Number},false, signal),

GetMemberOptions: (GridProps: GridProps, signal?: AbortSignal) => invokeFunction<PickListItem[]>("GetMemberOptions", {GridProps},true, signal),

SendMail: (Input: CommandMessage, signal?: AbortSignal) => invokeFunction<any>("SendMail", {Input},true, signal),

FindEmailAddresses: (input: string, signal?: AbortSignal) => invokeFunction<{Key: string, Value: string}[]>("FindEmailAddresses", {input},false, signal),

SetSetting: (Name: string,Value: string,Group: SettingGroup,RecordType?: string,ReportID?: number, signal?: AbortSignal) => invokeFunction<any>("SetSetting", {Name,Value,Group,RecordType,ReportID},false, signal),

SetOptions: (Changes?: { [index:string]: any }, signal?: AbortSignal) => invokeFunction<any>("SetOptions", {Changes},true, signal),

GetAllIDs: (GridProps: GridProps, signal?: AbortSignal) => invokeFunction<number[]>("GetAllIDs", {GridProps},true, signal),

OptionList: ( signal?: AbortSignal) => invokeFunction<RecordsData>("OptionList", {},false, signal),

GetSuggestions: (GridProps: GridProps, signal?: AbortSignal) => invokeFunction<string[]>("GetSuggestions", {GridProps},true, signal),

SetColumns: (Choices: ColumnChoice, signal?: AbortSignal) => invokeFunction<any>("SetColumns", {Choices},true, signal),

CommandData: (ChangedProperty: string,Values?: { [index:string]: any },SubIndex?: number,SubField?: string, signal?: AbortSignal) => invokeFunction<InputData>("CommandData", {ChangedProperty,Values,SubIndex,SubField},true, signal),

InputCommand: (Command: string,Values?: { [index:string]: any }, signal?: AbortSignal) => invokeFunction<{Key: string, Value: any}>("InputCommand", {Command,Values},true, signal),

RecordData: (RecordType: string,Values?: { [index:string]: any },ChangedFieldName?: string,Index?: number,SubFieldName?: string,SettingGroup?: SettingGroup, signal?: AbortSignal) => invokeFunction<RecordsData>("RecordData", {RecordType,Values,ChangedFieldName,Index,SubFieldName,SettingGroup},true, signal),

SaveRecord: (RecordType: string,Values?: { [index:string]: any }, signal?: AbortSignal) => invokeFunction<RecordsData>("SaveRecord", {RecordType,Values},true, signal),

DoCommandMulti: (Command: string,GridProps: GridProps,FieldName?: string,Value?: any,ReportFieldID?: number,Params?: string[],PrintFilters?: { [index:number]: any }, signal?: AbortSignal) => invokeFunction<{Key: string, Value: any}>("DoCommandMulti", {Command,GridProps,FieldName,Value,ReportFieldID,Params,PrintFilters},true, signal),

GlobalSearch: (Term: string, signal?: AbortSignal) => invokeFunction<SearchResultGroup[]>("GlobalSearch", {Term},false, signal),

MarkReportSeen: (ReportID: number, signal?: AbortSignal) => invokeFunction<any>("MarkReportSeen", {ReportID},false, signal),

FieldCount: (GridProps: GridProps,Field: string,Term?: string, signal?: AbortSignal) => invokeFunction<FieldCountData[]>("FieldCount", {GridProps,Field,Term},true, signal),

PageRecord: (RecordType: string,RecordID?: number,PresetValues?: { [index:string]: any },AddingRecord?: { [index:string]: any },AddingRecordType?: string,AddingField?: string,IsFirstLoad?: boolean, signal?: AbortSignal) => invokeFunction<RecordsData>("PageRecord", {RecordType,RecordID,PresetValues,AddingRecord,AddingRecordType,AddingField,IsFirstLoad},true, signal),

GetRecordAlerts: (RecordType: string,RecordID: number, signal?: AbortSignal) => invokeFunction<{ [index:number]: string }>("GetRecordAlerts", {RecordType,RecordID},false, signal),

GetTotals: (GridProps: GridProps, signal?: AbortSignal) => invokeFunction<GridSums>("GetTotals", {GridProps},true, signal),

CommandOptions: (GridProps: GridProps, signal?: AbortSignal) => invokeFunction<PickListItem[]>("CommandOptions", {GridProps},true, signal),

Commands: (GridProps: GridProps, signal?: AbortSignal) => invokeFunction<ColumnData[]>("Commands", {GridProps},true, signal),

QuickSearch: (GridProps: GridProps,ShowBarFilters?: boolean,ClientTime?: Date, signal?: AbortSignal) => invokeFunction<ViewTable>("QuickSearch", {GridProps,ShowBarFilters,ClientTime},true, signal),

Preview: (RecordType: string,ID: number, signal?: AbortSignal) => invokeFunction<RecordsData>("Preview", {RecordType,ID},false, signal),

Login: (LoginUserName: string,LoginPassword: string, signal?: AbortSignal) => invokeFunction<LoginResult>("Login", {LoginUserName,LoginPassword},false, signal),

LogOut: ( signal?: AbortSignal) => invokeFunction<any>("LogOut", {},false, signal),

Localize: (value: string, signal?: AbortSignal) => invokeFunction<string>("Localize", {value},false, signal),

VectorIcon: (Name: string, signal?: AbortSignal) => invokeFunction<string>("VectorIcon", {Name},false, signal),

GetDashboardData: ( signal?: AbortSignal) => invokeFunction<DashboardData>("GetDashboardData", {},false, signal),

GetAppData: ( signal?: AbortSignal) => invokeFunction<AppData>("GetAppData", {},false, signal),

GetColumnData: (Group: SettingGroup,Key?: string,RecordType?: string,ForAll?: boolean,ReportID?: number, signal?: AbortSignal) => invokeFunction<ColumnData[]>("GetColumnData", {Group,Key,RecordType,ForAll,ReportID},false, signal),

UITexts: (Language: string, signal?: AbortSignal) => invokeFunction<{ [index:string]: string }>("UITexts", {Language},false, signal),

ClearCache: ( signal?: AbortSignal) => invokeFunction<any>("ClearCache", {},false, signal),

GetChatbotUrl: ( signal?: AbortSignal) => invokeFunction<string>("GetChatbotUrl", {},false, signal),

RecordTypes: ( signal?: AbortSignal) => invokeFunction<EntityData[]>("RecordTypes", {},false, signal),

ReportFields: (RecordType: string, signal?: AbortSignal) => invokeFunction<ColumnData[]>("ReportFields", {RecordType},false, signal),

}